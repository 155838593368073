import React from "react";
import {Helmet} from "react-helmet-async";
import { SettingsContext } from "./settings";
import {ButtonGroup, Button} from "react-bootstrap";
import {Sun, Moon} from "react-bootstrap-icons";
import {Settings} from "../models/settings";


/* ====== ThemeCss component ====== */

export function ThemeCss(props: any) {

    function renderStylesheets(newTheme: string) {
        return (
            <Helmet>
                {
                    themes.map(theme => {
                        let href = `/css/themes/${theme}/bootstrap.min.css`;
                        return theme === newTheme
                            ? <link rel="stylesheet" href={href} key={theme}/>
                            : <link rel="preload" as="style" href={href} key={theme}/>
                    })
                }
            </Helmet>
        )
    }

    return (
        <SettingsContext.Consumer>
            {({settings, update}) => renderStylesheets(getThemeName(settings))}
        </SettingsContext.Consumer>
    )
}


/* ====== DarkModeSwitch component ====== */

export function DarkModeSwitch() : JSX.Element {
    return(
        <SettingsContext.Consumer>
            {({settings, update}) =>
                <ButtonGroup>
                    <Button
                        size="sm"
                        onClick={event => update({ darkMode: false})}
                        variant={settings.darkMode ? "secondary" : "primary"}>
                        <Sun />
                    </Button>
                    <Button
                        size="sm"
                        onClick={event => update({ darkMode: true})}
                        variant={settings.darkMode ? "primary" : "secondary"}>
                        <Moon />
                    </Button>
                </ButtonGroup>
            }
        </SettingsContext.Consumer>
    )
}


/* ====== ThemeContainer component ====== */

export function ThemeContainer(props: React.PropsWithChildren<any>): JSX.Element {
    return (
        <SettingsContext.Consumer>
            {({settings, update}) =>
                <div id="theme-container" className={getThemeName(settings)}>
                    {props.children}
                </div>
            }
        </SettingsContext.Consumer>
    );
}


/* ====== Helper functions ====== */

const themes = [
    "flatly",
    "darkly"
];


function getThemeName(settings: Settings) {
    return settings.darkMode ? themes[1] : themes[0]
}

