import {Arrow} from "./constraints/arrow";
import {Diagonal} from "./constraints/diagonal";
import {KillerCage} from "./constraints/killerCage";
import {SetupProps} from "./props";
import {Thermometer} from "./constraints/thermometer";

export function Constraints(props: SetupProps) {
    return (
        <g className="grid-constraints">
            <Thermos {...props} />
            <Arrows {...props} />
            <Diagonals {...props} />
        </g>
    );
}

export function ForegroundConstraints(props: SetupProps) {
    return (
        <g className="grid-constraints">
            <KillerCages {...props} />
        </g>
    );
}

function Arrows(props: SetupProps) {
    let arrows = props.setup.arrows;
    if (arrows.length > 0) {
        return (
            <g className="grid-arrows">
                {arrows.map(a => <Arrow {...a} key={a.arrowRefs.join(",")} />)}
            </g>
        );
    }
    else {
        return null;
    }
}

function Diagonals(props: SetupProps) {
    let diagonals = props.setup.diagonals;
    if (diagonals.length > 0) {
        return (
            <g className="grid-diagonals">
                {
                    diagonals.map(d => (
                        <Diagonal size={props.setup.size}
                                  forward={d.forward}
                                  key={d.forward.toString()}
                        />
                    ))
                }
            </g>
        )
    }
    else {
        return null;
    }
}

function Thermos(props: SetupProps) {
    let thermos = props.setup.thermos;
    if (thermos.length > 0) {
        return (
            <g className="grid-thermometers">
                {
                    thermos.map(t => <Thermometer
                        type={t.type}
                        refs={t.refs}
                        colour={t.colour}
                        key={t.refs.join(",")} />
                    )
                }
            </g>
        );
    }
    else {
        return null;
    }
}

function KillerCages(props: SetupProps) {
    if (props.setup.killerCages.length > 0) {
        return (
            <g className="grid-killerCages">
                {
                    props.setup.killerCages.map(k =>
                        <KillerCage
                            refs={k.refs}
                            total={k.total}
                            key={k.refs.join(",")} />
                    )
                }
            </g>
        );
    }
    else {
        return null;
    }
}
