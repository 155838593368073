import React from "react";
import {Game} from "../models/game/game";
import {GridPlay, GridSelection, GridSetup} from "./grid/props";
import {Conflict} from "../models/game/constraints";
import {ControlPanelState} from "./controlPanel/props";
import {Action, UpdateSection} from "../models/game/actions";
import {AddConstraintAction} from "../models/game/actions/loading";
import {Thermometer, ThermometerType} from "../models/game/constraints/thermometer";
import {CellRef} from "../models/common";
import _ from "lodash";
import {KnightsMoveConstraint} from "../models/game/constraints/antiknight";

export class GameContextProps {
    givens?: string;
    fPuzzlesData?: string;
}


export class GameState {
    game?: Game;
    setup?: GridSetup;
    selection?: GridSelection;
    play?: GridPlay;
    conflicts?: Conflict[];
    controls?: ControlPanelState;
    doAction?: (action: Action) => void;
}

export const GameContext = React.createContext<GameState>(new GameState());

export class GameContextProvider extends React.Component<GameContextProps, GameState> {
    constructor(props: GameContextProps) {
        super(props);
        this.doAction = this.doAction.bind(this);

        let game: Game;

        console.log(props);
        if (props.givens) {
            game = Game.load(props.givens);
        }
        else if (props.fPuzzlesData) {
            game = Game.fromFPuzzles(props.fPuzzlesData);
        }
        else {
            game = lightSpeed();
        }

        this.state = {
            game: game,
            setup: new GridSetup(game.grid),
            selection: new GridSelection(game.grid),
            play: new GridPlay(game.grid, game),
            controls: new ControlPanelState(game),
            doAction: this.doAction
        }
    }

    private doAction(action: Action) {
        if (!this.state.game) {
            return;
        }

        this.state.game.apply(action);
        let grid = this.state.game.grid;
        let game = this.state.game;
        let type = action.type;

        if (type.updates(UpdateSection.design)) {
            this.setState({
                setup: new GridSetup(grid)
            });
        }

        if (type.updates(UpdateSection.selection)) {
            this.setState({
                selection: new GridSelection(grid)
            });
        }

        if (type.updates(UpdateSection.play)) {
            this.setState({
                play: new GridPlay(grid, game)
            });
        }

        if (type.updates(UpdateSection.conflicts)) {
            this.setState({
                conflicts: grid.getConflicts()
            });
        }

        if (type.updates(UpdateSection.controls)) {
            this.setState({
                controls: new ControlPanelState(game)
            });
        }
    }

    render() {
        return (
            <GameContext.Provider value={this.state}>
                {this.props.children}
            </GameContext.Provider>
        )
    }
}


function sampleClassic(): Game {
    return Game.load(
        "000000010400000000020000000000050407008000300001090000300400200050100000000806000"
    );
}

function sampleSquiggly() {
    let givens =
        "010000040" +
        "047608902" +
        "100704030" +
        "900070006" +
        "703000109" +
        "400010007" +
        "030201004" +
        "201407560" +
        "070000090";

    let regions =
        "111122333" +
        "111222333" +
        "411222533" +
        "444255563" +
        "444456666" +
        "745558666" +
        "775888996" +
        "777888999" +
        "777889999";

    return Game.load(givens, regions);
}

function lightSpeed() {
    let givens =
        "002000000" +
        "000009000" +
        "000000009" +
        "070000000" +
        "000090000" +
        "000000020" +
        "400000000" +
        "000500000" +
        "000000800";

    let thermo1 = new Thermometer(
        ThermometerType.normal,
        _.range(9, 4, -1)
            .map(col => CellRef.get(1, col))
    );

    let thermo2 = new Thermometer(
        ThermometerType.normal,
        _.range(9, 3, -1)
            .map(row => CellRef.get(row, 9))
    );

    let thermo3 = new Thermometer(
        ThermometerType.normal,
        [
            CellRef.get(1, 9),
            CellRef.get(2, 9)
        ]
    );

    let thermo4 = new Thermometer(
        ThermometerType.normal,
        [
            CellRef.get(9, 9),
            CellRef.get(9, 8)
        ]
    );

    let game = Game.load(givens);
    game.apply(new AddConstraintAction(new KnightsMoveConstraint(false)));
    game.apply(new AddConstraintAction(thermo1));
    game.apply(new AddConstraintAction(thermo2));
    game.apply(new AddConstraintAction(thermo3));
    game.apply(new AddConstraintAction(thermo4));

    return game;
}