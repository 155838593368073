import _ from "lodash";
import {CellRef} from "../../common";
import {Constraint} from "../constraints";
import {Cell, Grid} from "../grid";

export class DiagonalConstraint extends Constraint {
    forward: boolean;

    constructor(forward: boolean) {
        super();
        this.forward = forward;
    }

    getCellsVisibleTo(grid: Grid, ref: CellRef): Cell[] {
        if (this.forward && ref.row === 1 + grid.size - ref.column) {
            return _.range(1, grid.size + 1)
                .map(n => CellRef.get(n, 1 + grid.size - n))
                .filter(r => r !== ref)
                .map(r => grid.getCell(r));
        }
        else if (!this.forward && ref.row === ref.column) {
            return _.range(1, grid.size + 1)
                .map(n => CellRef.get(n, n))
                .filter(r => r !== ref)
                .map(r => grid.getCell(r));
        }
        else {
            return [];
        }
    }

    override get name(): string {
        return (this.forward ? "forward" : "backward") + " diagonal";
    }

    override get rules(): string {
        return `Cells on the ${this.name} can not contain the same digit.`;
    }
}