import {CellRef} from "../../../models/common";
import {FunctionComponent, PropsWithChildren} from "react";

export interface CellGroupProps extends PropsWithChildren<any> {
    cellRef: CellRef;
    className: string;
}

export const CellGroup: FunctionComponent<CellGroupProps> = (props) => {
    let offsetX = (props.cellRef.column - 1) * 100;
    let offsetY = (props.cellRef.row - 1) * 100;
    return (
        <g transform={`translate(${offsetX},${offsetY})`} className={props.className}>
            {props.children}
        </g>
    )
}