import {CellRef} from "../../common";
import {Constraint} from "../constraints";
import {Cell, Grid} from "../grid";

export class KnightsMoveConstraint extends Constraint {
    readonly wrapAround: boolean;

    constructor(wrapAround: boolean) {
        super();
        this.wrapAround = wrapAround;
    }

    override getCellsVisibleTo(grid: Grid, ref: CellRef): Cell[] {
        let cells: Cell[] = [];
        for (let a = -2; a <= 2; a++) {
            for (let b = -2; b <= 2; b++) {
                if (a !== b && a !== -b && a !== 0 && b !== 0) {
                    let newRow = ref.row + a;
                    let newCol = ref.column + b;
                    let newRef = CellRef.get(newRow, newCol);
                    if (this.wrapAround) {
                        newRef = grid.wrap(newRef);
                    }

                    if (grid.checkBounds(newRef)) {
                        let cell = grid.getCell(newRef);
                        cells.push(cell);
                    }
                }
            }
        }

        return cells;
    }

    override get name(): string {
        return "knight's move";
    }

    override get rules(): string {
        return "Cells separated by a knight's move (in chess) can not contain the same digit.";
    }
}