import {CellRef} from "../common";
import {Cell, Grid} from "./grid";


/* ====== Constraint class ====== */

/**
 * The base class for all constraints in the grid.
 */

export class Constraint {

    /**
     * Checks whether two cells can see each other.
     * @param cell1
     * @param cell2
     */

    getCellsVisibleTo(grid: Grid, ref: CellRef): Cell[] {
        return [];
    }


    /**
     * Checks to see if two cells are in conflict with each other.
     * @param ref1
     * @param ref2
     * @param value1
     * @param value2
     */

    checkConflict(grid: Grid, ref1: CellRef, ref2: CellRef, value1?: number, value2?: number): Conflict | null {
        if ((value1 === value2) && (typeof(value1) === "number")) {
            return new Conflict(this, ref1, ref2,
                `${ref1.toString()} sees ${ref2.toString()} by ${this.name}.`);
        }
        else {
            return null;
        }
    }

    /**
     * Gets all cells that are in conflict with the cell at the given reference.
     */

    getConflicts(grid: Grid, ref: CellRef) : Conflict[] {
        let cell1 = grid.getCell(ref);
        if (typeof(cell1.digit) !== 'number') {
            return [];
        }

        let conflicts: Conflict[] = [];
        for (let cell2 of this.getCellsVisibleTo(grid, ref)) {
            let conflict = this.checkConflict(grid, ref, cell2.ref, cell1.digit, cell2.digit);
            if (conflict) {
                conflicts.push(conflict);
            }
        }

        return conflicts;
    }


    /**
     * Gets the name of the constraint.
     */

    get name(): string {
        throw new Error("Not implemented.");
    }

    /**
     * Gets the rules for this constraint.
     */

    get rules(): string {
        throw new Error("Not implemented.");
    }
}

/* ====== Conflict class ====== */

export class Conflict {
    readonly constraint: Constraint;
    readonly ref1: CellRef;
    readonly ref2: CellRef;
    readonly description: string;
    readonly key: string;

    constructor(constraint: Constraint, ref1: CellRef, ref2: CellRef, description: string) {
        if (ref1.row < ref2.row || (ref1.row === ref2.row && ref1.column < ref2.column)) {
            this.ref1 = ref1;
            this.ref2 = ref2;
        }
        else {
            this.ref1 = ref2;
            this.ref2 = ref1;
        }

        this.constraint = constraint;
        this.description = description;
        this.key = `${ref1.toString()}:${ref2.toString()}`;
    }
}