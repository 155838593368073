import {Card} from "react-bootstrap";

export interface MetadataProps {
    title?: string;
    author?: string;
    rules?: string;
}

export function Metadata(props: MetadataProps) {
    return (
        <Card className="shadow">
            <Card.Header>
                <h2>
                    {props.title}
                </h2>
            </Card.Header>
            <Card.Body>
                {props.author ? <Card.Title>By {props.author}</Card.Title> : null}
                {props.rules ? <Card.Text>{props.rules}</Card.Text> : null}
            </Card.Body>
        </Card>
    )
}