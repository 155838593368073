import "./thermometer.css";

import {CellRef} from "../../../models/common";
import * as constraints from "../../../models/game/constraints/thermometer";
import {Line} from "../rendering/line";

export class ThermometerProps {
    type: constraints.ThermometerType;
    refs: CellRef[];
    colour?: string;

    constructor(thermo: constraints.Thermometer) {
        this.type = thermo.type;
        this.refs = thermo.refs;
    }
}

export function Thermometer(props: ThermometerProps) {
    return (
        <g className="thermometer">
            <Line
                roundness={25}
                cells={props.refs}
                strokeWidth={20}
                strokeLinecap="round"
                stroke={props.colour}
                fill="none" />
            <circle
                cx={props.refs[0].column * 100 - 50}
                cy={props.refs[0].row * 100 - 50}
                r={30}
                stroke="none"
                fill={props.colour} />
        </g>
    );
}