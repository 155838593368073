import LZString from "lz-string";
import {CellRef} from "../common";
import {KingsMoveConstraint} from "../game/constraints/antiking";
import {KnightsMoveConstraint} from "../game/constraints/antiknight";
import {Arrow} from "../game/constraints/arrow";
import {DiagonalConstraint} from "../game/constraints/diagonal";
import {CageArithmetic, KillerCage} from "../game/constraints/killerCage";
import {NonConsecutiveConstraint} from "../game/constraints/nonconsecutive";
import {Thermometer, ThermometerType} from "../game/constraints/thermometer";
import {Game} from "../game/game";
import {Grid} from "../game/grid";
import {FPuzzle} from "./model";

export function importFPuzzles(game: Game, data: string) {
    let json = LZString.decompressFromBase64(data);
    if (!json) {
        throw new Error("F-Puzzles data is not valid.");
    }

    let fpuzzle = JSON.parse(json) as FPuzzle;
    game.grid = Grid.createEmpty(fpuzzle.size);
    game.grid.setDefaultRegions();
    game.metadata.title = fpuzzle.title || "";
    game.metadata.author = fpuzzle.author || "";
    game.metadata.rules = fpuzzle.ruleset || "";

    for (let row = 0; row < game.grid.size; row++) {
        for (let column = 0; column < game.grid.size; column++) {
            let ref = CellRef.get(row + 1, column + 1);
            let fpCell = fpuzzle.grid[row][column];
            let ourCell = game.grid.getCell(ref);
            if (fpCell.value) {
                ourCell.digit = fpCell.value;
            }
            if (fpCell.given) {
                ourCell.isGiven = fpCell.given;
            }
            if (fpCell.region) {
                ourCell.region = fpCell.region;
            }
            if (fpCell.centerPencilMarks) {
                fpCell.centerPencilMarks.forEach(m => ourCell.candidates.add(m));
            }
            if (fpCell.cornerPencilMarks) {
                fpCell.cornerPencilMarks.forEach(m => ourCell.hints.add(m));
            }
            if (fpCell.c) {
                // TODO: import highlight colour
                console.warn(`[FPuzzles import] Highlight colour found in ${ref}: not yet implemented.`);
            }
            if (fpCell.extreme) {
                // TODO: import maximum/minimum constraints
                console.warn(`[FPuzzles import] Maximum/minimum constraint found in ${ref}: not yet implemented.`);
            }
            if (fpCell.parity) {
                // TODO: import odd/even constraints
                console.warn(`[FPuzzles import] Odd/even constraint found in ${ref}: not yet implemented.`);
            }
        }
    }

    if (fpuzzle.antiknight) {
        game.grid.constraints.push(new KnightsMoveConstraint(false));
    }

    if (fpuzzle.antiking) {
        game.grid.constraints.push(new KingsMoveConstraint(false));
    }

    if (fpuzzle.disjointgroups) {
        // TODO: add disjoint groups constraint.
        console.warn(`[FPuzzles import] Disjoint groups constraint found: not yet implemented.`);
    }

    if (fpuzzle.nonconsecutive) {
        game.grid.constraints.push(new NonConsecutiveConstraint(false));
    }

    if (fpuzzle.highlightConflicts === false) {
        // TODO: add option to disable conflict highlighting.
        console.warn(`[FPuzzles import] Disable conflict highlighting: not yet implemented.`);
    }

    if ((fpuzzle as any)["diagonal+"] === true) {
        game.grid.constraints.push(new DiagonalConstraint(true));
    }

    if ((fpuzzle as any)["diagonal-"] === true) {
        game.grid.constraints.push(new DiagonalConstraint(false));
    }

    if (fpuzzle.extraregion) {
        // TODO: add extra region constraints.
        console.warn(`[FPuzzles import] Extra region constraints found: not yet implemented.`);
    }

    if (fpuzzle.odd) {
        // TODO: import odd/even constraints
        console.warn(`[FPuzzles import] Odd constraints found: not yet implemented.`);
    }

    if (fpuzzle.even) {
        // TODO: import odd/even constraints
        console.warn(`[FPuzzles import] Even constraints found: not yet implemented.`);
    }

    if (fpuzzle.thermometer) {
        fpuzzle.thermometer.forEach(t =>
            t.lines.forEach(l =>
                game.grid.constraints.push(
                    new Thermometer(
                        ThermometerType.normal,
                        l.map(c => CellRef.get(c))
                    )
                )
            )
        );
    }

    if (fpuzzle.palindrome) {
        // TODO: import palindromes
        console.warn(`[FPuzzles import] Palindrome constraints found: not yet implemented.`);
    }

    if (fpuzzle.killercage) {
        fpuzzle.killercage.forEach(c => {
            game.grid.constraints.push(
                new KillerCage(
                    c.cells.map(ref => CellRef.get(ref)),
                    false,
                    CageArithmetic.additive,
                    c.value ? parseInt(c.value, 10) : undefined
                )
            )
        })
    }

    if (fpuzzle.littlekillersum) {
        // TODO: import little killer sums
        console.warn(`[FPuzzles import] Little killer sum constraints found: not yet implemented.`);
    }

    if (fpuzzle.sandwichsum) {
        // TODO: import sandwich sums
        console.warn(`[FPuzzles import] Sandwich sum constraints found: not yet implemented.`);
    }

    if (fpuzzle.difference) {
        // TODO: import differences (white kropki dots)
        console.warn(`[FPuzzles import] Difference constraints found: not yet implemented.`);
    }

    if (fpuzzle.negative) {
        // TODO: import negative constraints (kropki and XV)
        console.warn(`[FPuzzles import] Negative constraints found: not yet implemented.`);
    }

    if (fpuzzle.ratio) {
        // TODO: import ratio constraints (black kropki dots)
        console.warn(`[FPuzzles import] Ratio constraints found: not yet implemented.`);
    }

    if (fpuzzle.clone) {
        // TODO: import clone constraints (black kropki dots)
        console.warn(`[FPuzzles import] Clone constraints found: not yet implemented.`);
    }

    if (fpuzzle.arrow) {
        let advanced = 0;
        fpuzzle.arrow.forEach(a => {
            a.lines.forEach(l => {
                let arrow = new Arrow(
                    a.cells.map(c => CellRef.get(c)),
                    l.map(c => CellRef.get(c))
                );
                if (arrow.horizontal || arrow.vertical) {
                    game.grid.constraints.push(arrow);
                }
                else {
                    advanced++;
                }
            })
        });

        // TODO: import nonstraight arrow constraints
        if (advanced > 0) {
            console.warn("[FPuzzles import] " +
                `${advanced} arrow constraints found with nonstraight roots: ` +
                "not yet implemented.");
        }
    }

    if (fpuzzle.betweenline) {
        // TODO: import between line constraints
        console.warn(`[FPuzzles import] Between line constraints found: not yet implemented.`);
    }

    if (fpuzzle.minimum) {
        // TODO: import between line constraints
        console.warn(`[FPuzzles import] Minimum constraints found: not yet implemented.`);
    }

    if (fpuzzle.maximum) {
        // TODO: import between line constraints
        console.warn(`[FPuzzles import] Maximum constraints found: not yet implemented.`);
    }

    if (fpuzzle.xv) {
        // TODO: import between line constraints
        console.warn(`[FPuzzles import] XV constraints found: not yet implemented.`);
    }

    if (fpuzzle.quadruple) {
        // TODO: import between line constraints
        console.warn(`[FPuzzles import] Quadruple constraints: not yet implemented.`);
    }

    if (fpuzzle.text) {
        // TODO: import text cosmetics
        console.warn(`[FPuzzles import] Text cosmetics: not yet implemented.`);
    }

    if (fpuzzle.circle) {
        // TODO: import circle cosmetics
        console.warn(`[FPuzzles import] Circle cosmetics: not yet implemented.`);
    }

    if (fpuzzle.rectangle) {
        // TODO: import text cosmetics
        console.warn(`[FPuzzles import] Rectangle cosmetics: not yet implemented.`);
    }

    if (fpuzzle.line) {
        // TODO: import text cosmetics
        console.warn(`[FPuzzles import] Line cosmetics: not yet implemented.`);
    }

    if (fpuzzle.cage) {
        // TODO: import text cosmetics
        console.warn(`[FPuzzles import] Cage cosmetics: not yet implemented.`);
    }
}