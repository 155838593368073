import * as React from "react";
import _ from "lodash";
import { CellRef } from "../../../models/common";


/* ====== Cage ====== */

/*
 * Renders the outline of a cage. This can be a region, a selection, a killer
 * cage, or anything else where we need to render an outline.
 */

export interface CageProps {
    readonly cells: CellRef[];
    readonly offset?: number;
    readonly className?: string;
}

export class Cage extends React.Component<CageProps> {

    getSegments(): (number | string)[][] {
        if (this.props.cells.length === 0) {
            return [];
        }

        let o = this.props.offset || 0;
        let maxCol = Math.max(..._.map(this.props.cells, c => c.column));
        let maxRow = Math.max(..._.map(this.props.cells, c => c.row));
        let array = _.range(0, maxRow + 2).map(n => _.range(0, maxCol + 2).map(m => false));
        for (let cell of this.props.cells) {
            array[cell.row][cell.column] = true;
        }

        let segments: (number | string)[][] = [];
        for (let row = 1; row <= maxRow; row++) {
            for (let col = 1; col <= maxCol; col++) {
                if (array[row][col]) {
                    let tl = array[row - 1][col - 1] ? 1 : 0;
                    let t = array[row - 1][col] ? 1 : 0;
                    let tr = array[row - 1][col + 1] ? 1 : 0;
                    let l = array[row][col - 1] ? 1 : 0;
                    let r = array[row][col + 1] ? 1 : 0;
                    let bl = array[row + 1][col - 1] ? 1 : 0;
                    let b = array[row + 1][col] ? 1 : 0;
                    let br = array[row + 1][col + 1] ? 1 : 0;
                    if (!t) {
                        segments.push([
                            (col-1) * 100 + o * (1 - l - l * tl),
                            (row - 1) * 100 + o,
                            col * 100 - o * (1 - r - r * tr),
                            (row - 1) * 100 + o,
                            `r${row}c${col}t`
                        ]);
                    }
                    if (!b) {
                        segments.push([
                            (col - 1) * 100 + o * (1 - l - l * bl),
                            row * 100 - o,
                            col * 100 - o * (1 - r - r * br),
                            row * 100 - o,
                            `r${row}c${col}b`
                        ]);
                    }
                    if (!l) {
                        segments.push([
                            (col - 1) * 100 + o,
                            (row - 1) * 100 + o * (1 - t - t * tl),
                            (col - 1) * 100 + o,
                            row * 100 - o * (1 - b - b * bl),
                            `r${row}c${col}l`
                        ]);
                    }
                    if (!r) {
                        segments.push([
                            col * 100 - o,
                            (row - 1) * 100 + o * (1 - t - t * tr),
                            col * 100 - o,
                            row * 100 - o * (1 - b - b * br),
                            `r${row}c${col}r`
                        ]);
                    }
                }
            }
        }

        return segments;
    }

    render() {
        let segments = this.getSegments();
        if (segments.length > 0) {
            return (
                <g className={this.props.className}>
                    {segments.map(segment => <line x1={segment[0]} y1={segment[1]} x2={segment[2]} y2={segment[3]}
                                                   key={segment[4]}/>)}
                </g>
            );
        }
        else {
            return null;
        }
    }
}
