/* ====== Timer class ====== */

export class Timer {

    private elapsedWhenStopped: number;
    private timeStarted: Date;
    private isRunning: boolean;

    constructor() {
        this.elapsedWhenStopped = 0;
        this.isRunning = false;
        this.timeStarted = new Date();
    }

    start() {
        if (!this.isRunning) {
            this.timeStarted = new Date();
            this.isRunning = true;
        }
    }

    stop() {
        if (this.isRunning) {
            this.elapsedWhenStopped = this.milliseconds;
            this.isRunning = false;
        }
    }

    reset() {
        this.isRunning = false;
        this.elapsedWhenStopped = 0;
    }

    get milliseconds() : number {
        if (this.isRunning) {
            return this.elapsedWhenStopped +
                new Date().getTime() -
                this.timeStarted.getTime();
        }
        else {
            return this.elapsedWhenStopped;
        }
    }

    get running() : boolean {
        return this.isRunning;
    }

    toString() {
        let totalSeconds = Math.floor(this.milliseconds / 1000);
        let seconds = totalSeconds % 60;
        let minutes = Math.floor(totalSeconds / 60) % 60;
        let hours = Math.floor(totalSeconds / 3600);

        return hours.toString() +
            ":" + ("00" + minutes).slice(-2) +
            ":" + ("00" + seconds).slice(-2);
    }
}
