import {Conflict, Constraint} from "../constraints";
import {Cell, Grid} from "../grid";
import {CellRef} from "../../common";

export enum ThermometerType {
    /**
     * Normal thermometer: digits must increase from the bulb end.
     */
    normal,
    /**
     * Slow thermometer: digits must increase or remain the same from the bulb end.
     */
    slow,
    /**
     * Swiss thermometer: digits must increase by 1 with every step from the bulb end.
     */
    swiss
}

export class Thermometer extends Constraint {

    readonly refs: CellRef[];
    readonly type: ThermometerType;

    constructor(type: ThermometerType, refs: CellRef[]) {
        super();
        this.refs = refs;
        this.type = type;
    }

    override getCellsVisibleTo(grid: Grid, ref: CellRef): Cell[] {
        return this.refs.filter(r => r !== ref).map(r => grid.getCell(r));
    }

    override getConflicts(grid: Grid, ref: CellRef): Conflict[] {
        let index = this.refs.indexOf(ref);
        if (index < 0) {
            return [];
        }

        let digit = grid.getCell(ref).digit;
        if (typeof(digit) !== "number") {
            return [];
        }

        let conflicts: Conflict[] = [];

        for (let ix = 0; ix < this.refs.length; ix++) {
            if (ix !== index) {
                let ix1 = Math.min(ix, index);
                let ix2 = Math.max(ix, index);
                let ref1 = this.refs[ix1];
                let ref2 = this.refs[ix2];
                let num1 = grid.getCell(ref1).digit;
                let num2 = grid.getCell(ref2).digit;

                if (typeof(num1) === "number" && typeof(num2) === "number") {
                    let numberDifference = num2 - num1;
                    let positionDifference = ix2 - ix1;
                    switch (this.type) {
                        case ThermometerType.normal:
                            if (numberDifference < positionDifference) {
                                conflicts.push(
                                    new Conflict(
                                        this,
                                        ref1,
                                        ref2,
                                        `Cells between ${ref1} and ${ref2} must increase from the bulb end. ` +
                                        `They only increase by ${numberDifference} in ${positionDifference} steps.`));
                            }
                            break;
                        case ThermometerType.slow:
                            if (numberDifference < 0) {
                                conflicts.push(
                                    new Conflict(
                                        this,
                                        ref1,
                                        ref2,
                                        `Cells between ${ref1} and ${ref2} must increase or remain the same from the bulb end.`));
                            }
                            break;
                        case ThermometerType.swiss:
                            if (numberDifference !== positionDifference) {
                                conflicts.push(
                                    new Conflict(
                                        this,
                                        ref1,
                                        ref2,
                                        `Cells between ${ref1} and ${ref2} must increase by 1 with each step from the bulb end. ` +
                                        `They increase by ${numberDifference} in ${positionDifference} steps.`));
                            }
                            break;
                    }
                }
            }
        }

        return conflicts;
    }

    override get name(): string {
        return "thermometer";
    }

    override get rules(): string {
        switch(this.type) {
            case ThermometerType.normal:
                return "Digits on thermometers must increase from the bulb end.";
            case ThermometerType.slow:
                return "Digits on slow thermometers must increase or stay the same from the bulb end.";
            case ThermometerType.swiss:
                return "Digits on Swiss thermometers must increase by exactly 1 each step from the bulb end.";
        }

        return "";
    }
}