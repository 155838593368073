import {CellRef} from "../../models/common";
import {Cell} from "../../models/game/grid";
import {PlayProps} from "./props";

export function Highlights(props: PlayProps) {
    return (
        <g className="grid-highlights">{
            props.play.getCells(c => c.highlights.length > 0)
                .map(c =>
                    <g className="grid-highlight-cell" key={c.ref.toString()}>{
                        c.highlights.map((h, ix) =>
                            <Highlight
                                cellRef={c.ref}
                                index={ix}
                                highlight={h}
                                segments={c.highlights.length}
                                key={h} />
                        )
                    }</g>
                )
        }</g>
    );
}

interface HighlightProps {
    cellRef: CellRef;
    index: number;
    highlight: number;
    segments: number;
}

function Highlight(props: HighlightProps) {

    function angleToPoint(angle: number): number[] {
        angle = angle % (2 * Math.PI);
        if (angle < 0) {
            angle += 2 * Math.PI;
        }
        let x = Math.sin(angle);
        let y = -Math.cos(angle);
        if (Math.abs(x) > Math.abs(y)) {
            y = y / Math.abs(x);
            x = Math.sign(x);
        }
        else {
            x = x / Math.abs(y);
            y = Math.sign(y);
        }

        return [x * 50 + 50, y * 50 + 50];
    }

    if (props.segments === 1) {
        return <rect
            x={100 * (props.cellRef.column - 1)}
            y={100 * (props.cellRef.row - 1)}
            width={100}
            height={100}
            className={`highlight-${props.highlight}`}
        />
    }
    else {
        let startAngle = props.segments <= 4
            ? [0, 0, 30, -60, 30][props.segments]
            : 0;
        startAngle = startAngle * 2 * Math.PI / 360;
        startAngle += (props.index / props.segments) * 2 * Math.PI;
        if (startAngle < 0) {
            startAngle += Math.PI * 2;
        }
        let endAngle = startAngle + 2 * Math.PI / props.segments;
        let points = [];
        points.push(angleToPoint(startAngle));
        if (startAngle < Math.PI / 4 && endAngle > Math.PI / 4) {
            points.push([100, 0]);
        }
        if (startAngle < 3 * Math.PI / 4 && endAngle > 3 * Math.PI / 4) {
            points.push([100, 100]);
        }
        if (startAngle < 5 * Math.PI / 4 && endAngle > 5 * Math.PI / 4) {
            points.push([0, 100]);
        }
        if (startAngle < 7 * Math.PI / 4 && endAngle > 7 * Math.PI / 4) {
            points.push([0, 0]);
        }
        if (startAngle < 9 * Math.PI / 4 && endAngle > 9 * Math.PI / 4) {
            points.push([100, 0]);
        }
        points.push(angleToPoint(endAngle));

        let path = `M ${props.cellRef.column * 100 - 50} ${props.cellRef.row * 100 - 50} `;
        for (let point of points) {
            let x = 100 * (props.cellRef.column - 1) + point[0];
            let y = 100 * (props.cellRef.row - 1) + point[1];
            path += `L ${x} ${y} `;
        }

        path += "Z";

        return <path d={path} className={`highlight highlight-${props.highlight}`} />
    }
}