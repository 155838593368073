import * as lzstring from "lz-string";

export class Settings {
    darkMode: boolean = true;
}


/* ====== Loading and saving from and to cookies ====== */

export function loadSettings(): Settings {
    let cookie = document.cookie.split(';').find(s => s.startsWith('settings='));
    if (cookie) {
        let json = lzstring.decompressFromEncodedURIComponent(cookie.substr(9));
        if (json) {
            return JSON.parse(json) as Settings;
        }
    }

    let settings = new Settings();
    if (window.matchMedia) {
        if(window.matchMedia('(prefers-color-scheme: dark)').matches) {
            settings.darkMode = true;
        }
    }

    return settings;
}

export function saveSettings(settings: Settings) {
    let encoded = lzstring.compressToEncodedURIComponent(JSON.stringify(settings));
    document.cookie = `settings=${encoded};path=/;max-age=31536000;`;
}