import "./conflicts.css";

import {ConflictProps} from "./props";
import {CellRef} from "../../models/common";


export function Conflicts(props: ConflictProps) {
    return (<g className="grid-conflicts">
        {
            props.conflicts.map(c => (
                <g className="conflict" key={c.key}>
                    {
                        c.ref1 === c.ref2
                            ? <X cellRef={c.ref1} />
                            : <>
                                <line className="conflict-connector"
                                      x1={c.ref1.column * 100 - 50} y1={c.ref1.row * 100 - 50}
                                      x2={c.ref2.column * 100 - 50} y2={c.ref2.row * 100 - 50}
                                />
                                <X cellRef={c.ref1} />
                                <X cellRef={c.ref2} />
                            </>
                    }
                </g>
            ))
        }
    </g>)
}


interface XProps {
    cellRef: CellRef;
}


function X(props: XProps) {
    return <>
        <line
            x1={props.cellRef.column * 100 - 35} y1={props.cellRef.row * 100 - 35}
            x2={props.cellRef.column * 100 - 65} y2={props.cellRef.row * 100 - 65}
        />
        <line
            x1={props.cellRef.column * 100 - 35} y1={props.cellRef.row * 100 - 65}
            x2={props.cellRef.column * 100 - 65} y2={props.cellRef.row * 100 - 35}
        />
    </>
}
