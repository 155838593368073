import {Constraint} from "../constraints";
import {CellRef} from "../../common";
import {Cell, Grid} from "../grid";


/* ====== DefaultConstraint ====== */

export class DefaultConstraint extends Constraint {

    override getCellsVisibleTo(grid: Grid, ref: CellRef): Cell[] {
        let cell = grid.getCell(ref);
        return grid.getCells(cell2 =>
            cell2.ref !== ref && (
                (cell2.ref.row === cell.ref.row ) ||
                (cell2.ref.column === cell.ref.column) ||
                (cell2.region === cell.region)
            )
        )
    }

    override get name(): string {
        return "sudoku";
    }

    override get rules(): string {
        return "Normal sudoku rules apply.";
    }
}
