import {EntryMode, Game} from "../../models/game/game";

export class ControlPanelState {
    game: Game;
    entryMode: EntryMode;

    constructor(game: Game) {
        this.game = game;
        this.entryMode = game.entryMode;
    }
}


/* ====== ControlPanelProps ====== */

export interface ControlPanelProps {
    controlState: ControlPanelState;
}