import "./digits.css";

import {PlayProps, SetupProps} from "./props";
import {CellRef} from "../../models/common";
import {Text} from "./rendering/text";

export function Digits(props: PlayProps & SetupProps) {
    return (
        <g className="grid-digits">
            {
                props.setup.getCells(cell => typeof(cell.givenDigit) === "number" )
                    .map(cell =>
                        <Digit
                            cellRef={cell.ref}
                            digit={cell.givenDigit || 0}
                            isGiven={true}
                            key={`${cell.ref.toString()}g`} />)
            }
            {
                props.play.getCells(cell => typeof(cell.digit) === "number" )
                    .map(cell =>
                        <Digit
                            cellRef={cell.ref}
                            digit={cell.digit || 0}
                            isGiven={false}
                            key={`${cell.ref.toString()}d`} />)
            }
            {
                props.play.getCells(cell => typeof(cell.digit) !== "number")
                    .map(cell =>
                        <Candidates
                            cellRef={cell.ref}
                            digits={cell.candidates}
                            key={`${cell.ref.toString()}c`} />)
            }
            {
                props.play.getCells(cell => typeof(cell.digit) !== "number")
                    .map(cell =>
                        <Hints
                            cellRef={cell.ref}
                            digits={cell.hints}
                            key={`${cell.ref.toString()}h`} />)
            }
        </g>
    );
}


export interface DigitProps {
    cellRef: CellRef;
    digit: number;
    isGiven: boolean;
}

export function Digit(props: DigitProps) {
    return (
        <Text
            cellRef={props.cellRef}
            dx={50} dy={15}
            text={props.digit.toString()}
            className={props.isGiven ? "given-digit" : "placed-digit"} />
    )
}


export interface HintProps {
    cellRef: CellRef;
    digits: number[];
}

export function Candidates(props: HintProps) {
    if (props.digits.length === 0) {
        return null;
    }

    let candidates = [...props.digits];
    candidates.sort((a, b) => a - b);
    let str = candidates.map(c => c.toString()).join('');
    if (str.length > 5) {
        let breakpoint = Math.ceil(str.length / 2);
        let str1 = str.substr(0, breakpoint);
        let str2 = str.substr(breakpoint);
        return <>
            <Text cellRef={props.cellRef} dx={50} dy={30} className="hints" text={str1} />
            <Text cellRef={props.cellRef} dx={50} dy={50} className="hints" text={str2} />
        </>;
    }
    else {
        return <Text cellRef={props.cellRef} dx={50} dy={40} className="hints" text={str}/>;
    }
}

export function Hints(props: HintProps) {
    if (props.digits.length === 0) {
        return <></>;
    }

    const rowSizes = [
        [0, 0, 0],
        [1, 0, 0],
        [2, 0, 0],
        [2, 0, 1],
        [2, 0, 2],
        [3, 0, 2],
        [3, 0, 3],
        [3, 2, 2],
        [3, 2, 3],
        [4, 2, 3],
        [4, 2, 4]
    ]

    let hints = [...props.digits];
    hints.sort((a, b) => a - b);
    let length = Math.min(hints.length, rowSizes.length);
    let rows = rowSizes[length];
    let row = 0;
    let column = 0;
    let result = [];
    for (let ix = 0; ix < length; ix++) {
        if (ix > 0) {
            column++;
            while (column >= rows[row]) {
                column = 0;
                row++;
            }
        }

        let hint = hints[ix];
        let rowSize = rows[row];
        let x = 12 + (column > 0 ? column * 76 / (rowSize - 1) : 0)
        let y = 2 + 38 * row;
        result.push(
            <Text
                cellRef={props.cellRef}
                dx={x} dy={y}
                className="hints"
                text={hint.toString()}
                key={hint.toString()} />
        );
    }

    return <>{result}</>;
}
