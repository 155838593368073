import {Action, ActionType} from "../actions";
import {Game} from "../game";
import {CellRef} from "../../common";
import {Grid} from "../grid";
import {Constraint} from "../constraints";
import {importFPuzzles} from "../../fpuzzles/importer";


/* ====== NewSudokuAction ====== */

/**
 * Creates a new, blank classic sudoku of size between 4 and 25 rows.
 */

export class NewSudokuAction extends Action {
    protected readonly size: number;

    constructor(size: number) {
        super(ActionType.loading);
        this.size = size;
    }

    protected setGivenDigitsAndDesignMode(game: Game) {
        game.designMode = true;
    }

    protected setRegions(game: Game) {
        game.grid.setDefaultRegions();
    }

    protected setMetadata(game: Game) {
        let metadata = game.metadata;
        metadata.title = this.size === 9 ? "Classic Sudoku" : `${this.size}x${this.size} Sudoku`;
        metadata.rules = "Normal sudoku rules apply. " +
            "Each row, column and region in the grid must contain each of the " +
            (this.size >= 10 ? "numbers" : "digits") +
            ` 1-${this.size} once only.`;
    }

    override apply(game: Game) {
        game.grid = Grid.createEmpty(this.size);
        this.setRegions(game);
        this.setGivenDigitsAndDesignMode(game);
        this.setMetadata(game);
    }
}


/* ====== LoadClassicAction ====== */

export class LoadClassicAction extends NewSudokuAction {
    private readonly givenDigits: string;
    private readonly regions?: string;

    constructor(givenDigits: string, regions?: string) {
        let size = Math.floor(Math.sqrt(givenDigits.length));
        if (size * size !== givenDigits.length) {
            throw new Error("The length of the given digits string is not a perfect square.");
        }
        if (regions && (regions.length !== givenDigits.length)) {
            throw new Error("The lengths of the region string and the given digits string do not match.");
        }

        super(size);
        this.givenDigits = givenDigits;
        this.regions = regions;
    }

    private lookup(str: string, ref: CellRef) {
        let pos = (ref.row - 1) * this.size + ref.column - 1;
        return parseInt(str.substr(pos, 1), this.size + 1);
    }

    override setGivenDigitsAndDesignMode(game: Game) {
        game.grid.forEachCell(cell => {
            let digit = this.lookup(this.givenDigits, cell.ref);
            if (!isNaN(digit) && digit >= 1 && digit <= this.size) {
                cell.digit = digit;
                cell.isGiven = true;
            }
        });

        game.designMode = false;
    }

    override setRegions(game: Game) {
        if (typeof(this.regions) === "string") {
            let regions = this.regions;
            game.grid.forEachCell(cell => {
                let region = this.lookup(regions, cell.ref);
                if (!isNaN(region) && region >= 1 && region <= this.size) {
                    cell.region = region;
                }
            })
        }
        else {
            super.setRegions(game);
        }
    }

    override setMetadata(game: Game) {
        super.setMetadata(game);
        game.metadata.title =
            (this.size !== 9 ? `${this.size}x${this.size} ` : "") +
            (this.regions ? "Irregular" : "Classic") +
            " Sudoku";
    }
}


/* ====== LoadFPuzzlesAction ====== */

export class LoadFPuzzlesAction extends Action {
    private data: string;

    constructor(data: string) {
        super(ActionType.loading);
        this.data = data;
    }

    override apply(game: Game) {
        importFPuzzles(game, this.data);
    }
}


/* ====== AddConstraintAction ====== */

export class AddConstraintAction extends Action {
    readonly constraint: Constraint;

    constructor(constraint: Constraint) {
        super(ActionType.setting);
        this.constraint = constraint;
    }

    override apply(game: Game) {
        super.apply(game);
        game.grid.constraints.push(this.constraint);
    }
}
