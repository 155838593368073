import { CellBase, CellRef, GridBase } from "../common";
import {Conflict, Constraint} from "./constraints";
import {DefaultConstraint} from "./constraints/default";
import {Game} from "./game";

/* ====== Cell class ====== */

/**
 * Represents the complete state of the cell.
 */

export class Cell extends CellBase {
    /**
     * The region in which the cell is placed.
     */
    region: number = 0;
    /**
     * A digit in the grid (placed or given).
     */
    digit?: number;
    /**
     * Indicates that the digit is a given digit.
     */
    isGiven = false;
    /**
     * Candidates (which will appear in the middle).
     */
    readonly candidates = new Set<number>();
    /**
     * Hints (aka Snyder notation, which will appear round the edges).
     */
    readonly hints = new Set<number>();
    /**
     * Coloured highlights.
     */
    readonly highlights = new Set<number>();
    /**
     * Indicates that this cell has been selected.
     */
    selected = false;
}


/* ====== Grid class ====== */

/**
 * Represents the complete state of the grid.
 */

export class Grid extends GridBase<Cell> {
    cursor: CellRef;
    constraints: Constraint[];

    private constructor(size: number) {
        super(size, ref => new Cell(ref));

        let midpoint = Math.ceil(size / 2);
        this.cursor = CellRef.get(midpoint, midpoint);
        this.constraints = [new DefaultConstraint()];
    }


    /* ====== forSelection ====== */
    /**
     * Applies the specified action to all the selected cells, or to the cell
     * under the cursor if there is no selection.
     * @param action
     */
    forSelection(action: (cell: Cell) => void): number {
        let count = 0;
        this.forEachCell(cell => {
            if (cell.selected) {
                action(cell);
                count++;
            }
        });

        if (count === 0) {
            action(this.getCell(this.cursor));
            count++;
        }

        return count;
    }

    /* ====== setDefaultRegions ====== */

    private getRegion(ref: CellRef) {
        const regionWidths = [
            0, 0, 0, 0,
            2, 5, 3, 7,
            4, 3, 5, 11,
            4, 13, 7, 5,
            4, 17, 6, 19,
            5, 7, 11, 23,
            6, 5
        ];

        let regionWidth = regionWidths[this.size];
        let regionHeight = this.size / regionWidth;
        return (
            Math.floor((ref.row - 1) / regionHeight) * regionHeight +
            Math.floor((ref.column - 1) / regionWidth) + 1
        );
    }

    setDefaultRegions() {
        this.forEachCell(cell => cell.region = this.getRegion(cell.ref));
    }

    /* ====== getSelection ====== */

    getSelection(): CellRef[] {
        return this.getCells(cell => cell.selected)
            .map(cell => cell.ref);
    }


    /* ====== setSelection ====== */

    setSelection(refs: CellRef[]) {
        refs.forEach(ref => this.getCell(ref).selected = true);
    }


    /* ====== getConflicts ====== */

    getConflicts(): Conflict[] {
        let cells = this.getCells(cell => typeof (cell.digit) === 'number' && !cell.isGiven);
        let conflicts: Conflict[] = [];
        let keys = new Set<string>();
        for (let cell of cells) {
            for (let constraint of this.constraints) {
                for (let conflict of constraint.getConflicts(this, cell.ref)) {
                    if (!keys.has(conflict.key)) {
                        conflicts.push(conflict);
                        keys.add(conflict.key);
                        console.log(conflict.description);
                    }
                }
            }
        }

        return conflicts;
    }

    checkCompleted() : boolean {
        if (this.all(cell => typeof(cell.digit) === 'number')) {
            return this.getConflicts().length === 0;
        }
        else {
            return false;
        }
    }


    static createEmpty(size: number) {
        if (size < 4 || size > 25) {
            throw new Error("Sudoku grids smaller than 4x4 or larger than 16x16 are not supported.");
        }

        if (size !== Math.floor(size)) {
            throw new Error("I don't know how to create a sudoku with a fractional sized grid.");
        }

        return new Grid(size);
    }

    static readonly empty = new Grid(0);
}
