import _ from "lodash";


/* ====== CellRef class ====== */

/**
 * The CellRef class is the class used to provide references for all cells in
 * the grid. It contains a row and a column, which will normally be in the range
 * 1-9, but can be any positive integer. By allowing zero or numbers larger than
 * the size of the grid, we can include gutters (e.g. for little killer clues or
 * sandwich clues).
 *
 * CellRefs should not be instantiated directly, but should be obtained using
 * the CellRef.get() static method. This ensures that two requests for the same
 * cell coordinates will always return the same CellRef instance.
 */

export class CellRef {
    readonly row: number;
    readonly column: number;

    private constructor(row: number, column: number) {

        this.row = row;
        this.column = column;
    }

    toString(): string {
        return `r${this.row}c${this.column}`;
    }

    toAltString(): string {
        return `${String.fromCharCode(64 + this.row)}${this.column}`;
    }

    private static cellRefCache : { [ref: string]: CellRef } = {}

    /* ====== getCellRef method ====== */

    /**
     * Gets a CellRef instance for the given coordinates. Coordinates can be
     * specified either as numbers or as a string in one of the two canonical
     * sudoku reference styles ("R5C3" or "E3").
     *
     * Rows and columns will normally be in the range 1-9, but can be any
     * positive integer. By allowing zero or numbers larger than the size of the
     * grid, we can include gutters (e.g. for little killer clues or sandwich
     * clues).
     */

    static get(rowOrRef: number | string, column?: number) : CellRef {
        let row, col: number;

        if (typeof rowOrRef === 'string') {
            let matches = rowOrRef.match(/^[Rr]([0-9]+)[Cc]([0-9]+)$/);
            if (matches) {
                row = parseInt(matches[1]);
                col = parseInt(matches[2]);
            }
            else if ((matches = rowOrRef.match(/^([@-Za-z])([0-9]+)$/))) {
                row = matches[1].toUpperCase().charCodeAt(0) - 64;
                col = parseInt(matches[2]);
            }
            else {
                throw new Error('The cell reference was not in the correct format.');
            }
        }
        else if (typeof rowOrRef === 'number') {
            if (typeof column === 'number') {
                row = rowOrRef;
                col = column;
            }
            else {
                throw new Error('Cell references must specify both row and column.');
            }
        }
        else {
            throw new Error('The cell reference was not in the correct format.');
        }

        row = Math.floor(row);
        col = Math.floor(col);

        let key = `r${row}c${col}`;
        if (CellRef.cellRefCache.hasOwnProperty(key)) {
            return CellRef.cellRefCache[key];
        }

        let ref = new CellRef(row, col);
        CellRef.cellRefCache[key] = ref;
        return ref;
    }
}


/* ====== Direction enumeration ====== */

export enum Direction {
    horizontal,
    vertical
}


/* ====== CellBase class ====== */

/**
 * This is the base class for anything cell-related.
 */

export class CellBase {
    readonly ref: CellRef

    constructor(ref: CellRef) {
        this.ref = ref;
    }
}


/* ====== GridBase class ====== */

/**
 * This is the base class for anything grid-related.
 */

export class GridBase<TCell extends CellBase> {
    private readonly cells: TCell[][];
    readonly size: number;

    /**
     * Creates a new instance of the grid, initialising the cells in each
     * row and column.
     *
     * @param size The size of the grid.
     * @param cellFactory A factory function to create the cells in the grid.
     */
    constructor(size: number, cellFactory: (ref: CellRef) => TCell) {
        this.size = Math.floor(size);
        this.cells =
            _.range(0, size).map(row =>
                _.range(0, size).map(col =>
                    cellFactory(CellRef.get(row + 1, col + 1))));
    }

    checkBounds(ref: CellRef) : boolean {
        return (
            ref.row >= 1 && ref.row <= this.size &&
            ref.column >= 1 && ref.column <= this.size
        );
    }

    wrap(ref: CellRef) : CellRef {
        let row = ((ref.row - 1) % this.size) + 1;
        if (row < 1) row += this.size;
        let column = ((ref.column - 1) % this.size ) + 1;
        if (column < 1) column += this.size;
        return CellRef.get(row, column);
    }


    /**
     * Gets a cell at a given CellRef.
     * @param ref The cell reference.
     */
    getCell(ref: CellRef): TCell {
        if (!this.checkBounds(ref)) {
            throw new Error(`The cell reference ${ref.toString()} is outside the grid.`);
        }

        return this.cells[ref.row - 1][ref.column - 1];
    }

    /**
     * Gets all cells matching a given condition.
     * @param predicate The condition to match the cells against.
     */
    getCells(predicate?: (cell: TCell) => boolean): TCell[] {
        if (predicate) {
            return _.flatten(
                this.cells.map(row => row.filter((cell, index) => predicate(cell)))
            )
        }
        else {
            return _.flatten(this.cells);
        }
    }

    /**
     * Checks to see if there are any cells matching the given condition.
     * @param predicate The condition to match the cells against.
     */
    any(predicate: (cell: TCell) => boolean): boolean {
        for (let row of this.cells) {
            for (let cell of row) {
                if (predicate(cell)) {
                    return true;
                }
            }
        }

        return false;
    }

    /**
     * Checks to see if all cells match the given condition.
     * @param predicate The condition to match the cells against.
     */
    all(predicate: (cell: TCell) => boolean): boolean {
        return !this.any(cell => !predicate(cell));
    }

    /**
     * Counts the number of cells matching the given condition.
     * @param predicate The condition to match the cells against.
     */
    count(predicate: (cell: TCell) => boolean): number {
        let num = 0;

        for (let row of this.cells) {
            for (let cell of row) {
                if (predicate(cell)) {
                    num++;
                }
            }
        }

        return num;
    }

    /**
     * Iterates over the cells, performing the given action on each of them.
     */
    forEachCell(action: (cell: TCell) => void) {
        this.cells.forEach(c => c.forEach(action));
    }

    /**
     * Given a grid reference, gets a new reference offset by a specific amount.
     */
    getOffset(ref: CellRef, dRow: number, dColumn: number): CellRef {
        dRow = Math.round(dRow);
        dColumn = Math.round(dColumn);

        let row = (ref.row + dRow) % this.size;
        let column = (ref.column + dColumn) % this.size;
        if (row < 1) {
            row += this.size;
        }

        if (column < 1) {
            column += this.size;
        }

        return CellRef.get(row, column);
    }
}
