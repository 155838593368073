import "../grid/highlights.css";

import _ from "lodash";
import {Button} from "react-bootstrap";
import {ArrowClockwise, ArrowCounterclockwise, BackspaceFill} from "react-bootstrap-icons";
import {RedoAction, UndoAction} from "../../models/game/actions/control";
import {getDeleteAction, getDigitAction} from "../../models/game/actions/solving";
import {EntryMode} from "../../models/game/game";

import {GameContext, GameState} from "../gameContext";
import "./numberPad.css";

export function NumberPad() {

    return <GameContext.Consumer>
        {value => {
            let game = value.game!;
            let doAction = value.doAction!;

            function undo() {
                doAction(new UndoAction());
            }

            function redo() {
                doAction(new RedoAction());
            }

            function handleDelete() {
                var deleteAction = getDeleteAction(game);
                if (deleteAction) {
                    doAction(deleteAction);
                }
            }

            return (
                <div className="numberpad">
                    {
                        value.game!.entryMode === EntryMode.Highlights
                            ? <Highlights /> : <Numbers />
                    }
                    <div className="numberpad-row">
                        <Button
                            variant="outline-primary"
                            size="lg"
                            onClick={undo}>
                            <ArrowCounterclockwise/>
                        </Button>
                        <Button
                            variant="outline-primary"
                            size="lg"
                            onClick={redo}>
                            <ArrowClockwise/>
                        </Button>
                        <Button
                            variant="outline-primary"
                            size="lg"
                            onClick={handleDelete}>
                            <BackspaceFill />
                        </Button>
                    </div>
                </div>
            )
        }}
    </GameContext.Consumer>
}

function Numbers() {

    function selectDigit(state: GameState, num: number) {
        state.doAction!(getDigitAction(state.game!, num));
    }

    return (
        <GameContext.Consumer>
            {value =>
                _.range(1, 9, 3).map(row =>
                    <div className="numberpad-row" key={row}>{
                        _.range(row, row + 3).map(num =>
                            <Button
                                variant="outline-primary"
                                size="lg"
                                onClick={() => selectDigit(value, num)} key={num}>
                                {num}
                            </Button>)
                    }</div>)
            }
        </GameContext.Consumer>
    )
}

function Highlights() {

    function selectHighlight(state: GameState, num: number) {
        state.doAction!(getDigitAction(state.game!, num));
    }

    return (
        <GameContext.Consumer>
            { value =>
                _.range(1, 12, 3).map(row =>
                    <div className="highlightPad-row" key={row}>{
                        _.range(row, row + 3).map(num =>
                            <Button
                                variant="outline-primary"
                                size="lg"
                                onClick={() => selectHighlight(value, num)} key={num}>
                                <svg xmlns="http://www.w3.org/2000/svg"
                                     width="1em" height="1em"
                                     viewBox="0 0 100 100">
                                    <rect x={0} y={0} width={100} height={100}
                                          className={`highlight highlight-${num}`} />
                                </svg>
                            </Button>
                        )
                    }</div>
                )
            }
        </GameContext.Consumer>
    )
}