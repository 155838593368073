import "./arrow.css";

import {CellRef, Direction} from "../../../models/common";
import * as constraints from "../../../models/game/constraints/arrow";
import {Line} from "../rendering/line";

export class ArrowProps {
    rootCell: CellRef;
    rootSize: number;
    rootDirection: Direction;
    arrowRefs: CellRef[];

    constructor(arrow: constraints.Arrow) {
        if (arrow.horizontal) {
            this.rootDirection = Direction.horizontal;
        }
        else if (arrow.vertical) {
            this.rootDirection = Direction.vertical;
        }
        else {
            throw new Error(
                "The arrow's root cells are not all in the same row or column, " +
                "or are not in consecutive cells.");
        }

        this.rootCell = arrow.rootRefs[0];
        this.rootSize = arrow.rootRefs.length;
        this.arrowRefs = arrow.arrowRefs;
    }
}

export function Arrow(props: ArrowProps) {
    return <g className="arrow">
        <Line cells={props.arrowRefs} />
        <ArrowHead {...props} />
        <ArrowRoot {...props} />
    </g>;
}


function ArrowRoot(props: ArrowProps) {
    let radius = 30;
    if (props.rootSize === 1) {
        return <circle
            className="arrow-root"
            cx={props.rootCell.column * 100 - 50}
            cy={props.rootCell.row * 100 - 50}
            r={radius} />
    }

    let x1, x2, x3, x4, y1, y2, y3, y4: number;

    if (props.rootDirection === Direction.horizontal) {
        x1 = props.rootCell.column * 100 - 50;
        x2 = x1 + 100 * (props.rootSize - 1);
        x3 = x2;
        x4 = x1;
        y1 = props.rootCell.row * 100 - 50 - radius;
        y2 = y1;
        y3 = y2 + 2 * radius;
        y4 = y3;
    }
    else {
        x1 = props.rootCell.column * 100 - 50 + radius;
        x2 = x1;
        x3 = x1 - 2 * radius;
        x4 = x3;
        y1 = props.rootCell.row * 100 - 50;
        y2 = y1 + 100 * (props.rootSize - 1);
        y3 = y2;
        y4 = y1;
    }

    let d = `M ${x1},${y1} ` +
        `L ${x2},${y2} ` +
        `A ${radius},${radius},0,0,1,${x3},${y3} ` +
        `L ${x4},${y4} ` +
        `A ${radius},${radius},0,0,1,${x1},${y1} ` +
        `L ${x1},${y1} ` +
        `Z`;

    return <path className="arrow-root" d={d} />;
}

function ArrowHead(props: ArrowProps) {
    let lastRef = props.arrowRefs[props.arrowRefs.length - 1];
    let lastButOne = props.arrowRefs[props.arrowRefs.length - 2];
    let dRow = lastRef.row - lastButOne.row;
    let dColumn = lastRef.column - lastButOne.column;

    let x = lastRef.column * 100 - 50;
    let y = lastRef.row * 100 - 50;
    let scale = 20 / Math.sqrt(dRow * dRow + dColumn * dColumn) ;

    let dx1 = (-dColumn-dRow) * scale;
    let dy1 = (+dColumn-dRow) * scale;
    let dx2 = (-dColumn+dRow) * scale;
    let dy2 = (-dColumn-dRow) * scale;

    let d = `M ${x+dx1},${y+dy1} L ${x},${y} L ${x+dx2},${y+dy2}`;

    return <path d={d} />;
}