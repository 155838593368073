import React from 'react';
import './App.css';
import {DarkModeSwitch, ThemeContainer} from "./components/theme";
import {Container, Nav, Navbar, NavDropdown} from "react-bootstrap";
import {GameContainer} from "./components/game";
import {GameContextProvider, GameContextProps} from "./components/gameContext";
import {useParams} from "react-router";

function App() {
    let params = useParams<GameContextProps>();

    return (
        <ThemeContainer>
            <GameContextProvider {...params}>
                <Navbar bg="dark" variant="dark" collapseOnSelect expand="sm">
                    <Container fluid>
                        <Navbar.Brand href="#home">Sudokuizer</Navbar.Brand>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className="me-auto">
                                <Nav.Link href="#home">Home</Nav.Link>
                                <Nav.Link href="#link">Link</Nav.Link>
                                <NavDropdown title="Dropdown" id="basic-nav-dropdown">
                                    <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                                    <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
                                    <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
                                </NavDropdown>
                            </Nav>
                        </Navbar.Collapse>
                        <Nav className="d-flex">
                            <DarkModeSwitch />
                        </Nav>
                    </Container>
                </Navbar>
                <GameContainer />
            </GameContextProvider>
        </ThemeContainer>
    );
}

export default App;
