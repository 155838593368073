import {ControlPanelProps} from "./props";
import {EntryModeSelector} from "./entryModeSelector";
import {NumberPad} from "./numberPad";

export function ControlPanel(props: ControlPanelProps) {
    return (
        <div className="control-panel">
            <EntryModeSelector mode={props.controlState.entryMode} />
            <NumberPad />
        </div>
    );
}