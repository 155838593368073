import {Action, ActionType} from "../actions";
import {EntryMode, Game} from "../game";


/* ====== SetEntryModeAction ====== */

export class SetEntryModeAction extends Action {
    private readonly entryMode: EntryMode

    constructor(entryMode: EntryMode) {
        super(ActionType.control);
        this.entryMode = entryMode;
    }

    override apply(game: Game) {
        game.entryMode = this.entryMode;
    }
}


/* ====== UndoAction ====== */

export class UndoAction extends Action {
    constructor() {
        super(ActionType.undoRedo);
    }

    override apply(game: Game) {
        game.undo();
    }
}

/* ====== UndoAction ====== */

export class RedoAction extends Action {
    constructor() {
        super(ActionType.undoRedo);
    }

    override apply(game: Game) {
        game.redo();
    }
}