import {Grid} from "./grid";
import {Action, UpdateSection} from "./actions";
import {LoadClassicAction, LoadFPuzzlesAction, NewSudokuAction} from "./actions/loading";
import {Timer} from "./timer";


/* ====== EntryMode enumeration ====== */

export enum EntryMode {
    /**
     * For placing digits in the grid.
     */
    Place,
    /**
     * For placing candidate digits in the centre of the cell.
     */
    Candidates,
    /**
     * For placing hints (Snyder notation) in the corners of the cell.
     */
    Hints,
    /**
     * For colouring the grid.
     */
    Highlights
}


/* ====== Metadata class ====== */

export class Metadata {
    title: string = "";
    author: string = "";
    rules: string = "";
}


/* ====== Game class ====== */

export class Game {
    private actions: Action[] = [];
    private redoActions: Action[] = [];
    grid: Grid;
    metadata: Metadata = new Metadata();
    designMode: boolean = false;
    entryMode: EntryMode = EntryMode.Place;
    timer: Timer = new Timer();
    completed: boolean = false;

    private constructor() {
        this.grid = Grid.empty;
        this.actions = [];
        this.timer.start();
    }

    apply(action: Action) {
        if (!this.timer.running && !action.allowOnPause) {
            return;
        }

        action.apply(this);
        if (action.type.shouldStore) {
            this.actions.push(action);
            this.redoActions = [];
        }

        if (action.type.updates(UpdateSection.play)) {
            if (this.grid.checkCompleted()) {
                this.timer.stop();
                this.completed = true;
            }
        }
    }

    undo() {
        let action = this.actions.pop();
        if (!action) return;
        if (action.type.canUndo(this.designMode)) {
            let selection = this.grid.getSelection();
            let cursor = this.grid.cursor;
            this.redoActions.push(action);
            this.grid = Grid.empty;
            this.metadata = new Metadata();
            this.actions.forEach(ac => ac.apply(this));
            this.grid.setSelection(selection);
            this.grid.cursor = cursor;
        }
        else {
            this.actions.push(action);
        }
    }

    redo() {
        let action = this.redoActions.pop();
        if (action) {
            action.apply(this);
            this.actions.push(action);
        }
    }


    static new(size: number = 9) {
        let game = new Game();
        game.apply(new NewSudokuAction(size));
        return game;
    }

    static load(givens: string, regions?: string) {
        let game = new Game();
        game.apply(new LoadClassicAction(givens, regions));
        return game;
    }

    static fromFPuzzles(data: string) {
        let game = new Game();
        game.apply(new LoadFPuzzlesAction(data));
        return game;
    }
}
