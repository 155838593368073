import "./killerCage.css";

import _ from "lodash";
import {CellRef} from "../../../models/common";
import * as constraints from "../../../models/game/constraints/killerCage";
import {Cage} from "../rendering/cage";
import {Text} from "../rendering/text";

export class KillerCageProps {
    refs: CellRef[];
    total?: number;
    colour?: string;
    totalColour?: string;

    constructor(killerCage: constraints.KillerCage) {
        this.refs = killerCage.refs;
        this.total = killerCage.total;
    }
}

export function KillerCage(props: KillerCageProps) {
    let sortedRefs = [...props.refs]
        .sort((a: CellRef, b: CellRef) => (a.row - b.row) || (a.column - b.column));

    return (
        <g className="killerCage">
            <Cage
                cells={props.refs}
                offset={10}
                className="killerCage-outline" />
            {
                _.isNumber(props.total)
                    ? <Text
                        cellRef={sortedRefs[0]}
                        dx={10}
                        dy={10}
                        text={props.total.toString()}
                        className="killerCage-label" />
                    : null
            }
        </g>
    )
}