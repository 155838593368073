import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {SettingsProvider} from "./components/settings";
import {ThemeCss} from "./components/theme";
import {HelmetProvider} from "react-helmet-async";
import {BrowserRouter, Route, Switch} from "react-router-dom";

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <SettingsProvider>
                <HelmetProvider>
                    <ThemeCss />
                    <Switch>
                        <Route path="/fpuzzles/:fPuzzlesData+">
                            <App />
                        </Route>
                        <Route path="/" exact>
                            <App/>
                        </Route>
                        <Route path="/classic/:givens">
                            <App/>
                        </Route>
                    </Switch>
                </HelmetProvider>
            </SettingsProvider>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
