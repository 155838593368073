import "./grid.css";

import React from "react";
import {Highlights} from "./highlights";
import {Background} from "./background";
import {Selection} from "./selection";
import {Constraints, ForegroundConstraints} from "./constraints";
import {Gridlines} from "./gridlines";
import {Digits} from "./digits";
import * as Props from "./props";
import {GameContext, GameState} from "../gameContext";
import {KeyBinding} from "../keyBindings";
import {
    MoveCursorAction,
    SelectOrDeselectAllAction, SetCursorAction,
    ToggleSelectionAction
} from "../../models/game/actions/selecting";
import {getDeleteAction, getDigitAction, PauseAction, RestartAction} from "../../models/game/actions/solving";
import {EntryMode} from "../../models/game/game";
import {RedoAction, SetEntryModeAction, UndoAction} from "../../models/game/actions/control";
import {Conflicts} from "./conflicts";
import {Paused} from "./paused";
import {CellRef} from "../../models/common";

export class Grid extends React.Component<Props.GridProps> {

    private mouseIsDown: boolean = false;
    private refOnDown: CellRef | null = null;

    constructor(props: Props.GridProps) {
        super(props);
        console.log("Recreating grid");

        this.renderContents = this.renderContents.bind(this);
    }

    private renderContents(value: GameState) {

        let width = 100 * value.setup!.size;
        let gutter = 2;
        let self = this;

        function shouldExtend(e: KeyboardEvent | React.MouseEvent | React.TouchEvent) : boolean {
            return e.ctrlKey ||
                (e.shiftKey && value.game!.grid.getCell(value.game!.grid.cursor).selected);
        }

        function handleArrowKey(e: KeyboardEvent) {
            if (e.key.startsWith("Arrow")) {
                let direction = e.key.substr(5);
                let dRow = 0;
                let dColumn = 0;
                switch(direction) {
                    case "Up":
                        dRow = -1;
                        break;
                    case "Down":
                        dRow = 1;
                        break;
                    case "Left":
                        dColumn = -1;
                        break;
                    case "Right":
                        dColumn = 1;
                        break;
                }

                let action = new MoveCursorAction(
                    dRow,
                    dColumn,
                    e.shiftKey,
                    shouldExtend(e)
                );

                value.doAction!(action);
            }
        }

        function toggleSelection() {
            value.doAction!(new ToggleSelectionAction());
        }

        function handleDigit(e: KeyboardEvent) {
            let digit = parseInt(e.key, 10);
            if (digit < 1 || digit > value.game!.grid.size) {
                return;
            }

            value.doAction!(getDigitAction(value.game!, digit));
        }

        function undo() {
            value.doAction!(new UndoAction());
        }

        function redo() {
            value.doAction!(new RedoAction());
        }

        function selectAll() {
            value.doAction!(new SelectOrDeselectAllAction(true));
        }

        function deselectAll() {
            value.doAction!(new SelectOrDeselectAllAction(false));
        }

        function setEntryMode(e: KeyboardEvent) {
            let entryMode: EntryMode;
            switch (e.key.toLowerCase()) {
                case "z":
                    entryMode = EntryMode.Place;
                    break;
                case "x":
                    entryMode = EntryMode.Hints;
                    break;
                case "c":
                    entryMode = EntryMode.Candidates;
                    break;
                case "v":
                    entryMode = EntryMode.Highlights;
                    break;
                default:
                    return;
            }

            value.doAction!(new SetEntryModeAction(entryMode));
        }

        function getRef(target: Element, cx: number, cy: number, radius: number) : CellRef | null {
            let rect = target.getBoundingClientRect();
            let x = (cx - rect.x) / rect.width * value.setup!.size;
            let y = (cy - rect.y) / rect.height * value.setup!.size;
            let column = Math.ceil(x);
            let row = Math.ceil(y);
            let ref = CellRef.get(row, column);
            if (!value.game!.grid.checkBounds(ref)) {
                return null;
            }

            if (radius >= 1) {
                return ref;
            }

            let dx = x + 0.5 - column;
            let dy = y + 0.5 - row;
            let delta = Math.sqrt(dx * dx + dy * dy);
            if (delta <= radius) {
                return ref;
            } else {
                return null;
            }
        }

        function gridInteractionStart(ref: CellRef | null, toggle: boolean, extend: boolean) {
            if (self.mouseIsDown || !ref || !value.game!.grid.checkBounds(ref)) {
                return;
            }

            value.doAction!(new SetCursorAction(ref, toggle, extend));
            self.refOnDown = ref;
            self.mouseIsDown = true;
        }

        function gridMouseDown(e: React.MouseEvent) {
            let ref = getRef(e.target as Element, e.clientX, e.clientY, 1);
            let extend = shouldExtend(e);

            gridInteractionStart(ref, e.shiftKey || e.ctrlKey, extend);
        }

        function gridTouchStart(e: React.TouchEvent) {
            if (e.touches.length === 1) {
                let ref = getRef(e.target as Element, e.touches[0].clientX, e.touches[0].clientY, 1);
                let extend = shouldExtend(e);

                gridInteractionStart(ref, e.shiftKey || e.ctrlKey, extend);
            }
        }

        function gridInteractionMove(ref: CellRef | null) {
            if (self.mouseIsDown && ref && ref !== self.refOnDown) {
                value.doAction!(new SetCursorAction(ref, false, true));
            }
        }

        function gridMouseMove(e: React.MouseEvent) {
            let ref = getRef(e.target as Element, e.clientX, e.clientY, 0.5);
            gridInteractionMove(ref);
        }

        function gridTouchMove(e: React.TouchEvent) {
            if (e.touches.length === 1) {
                let touch = e.touches[0];
                let ref = getRef(e.target as Element, touch.clientX, touch.clientY, 0.5);
                gridInteractionMove(ref);
            }
        }

        function gridMouseUp(e: React.MouseEvent) {
            self.mouseIsDown = false;
            self.refOnDown = null;
        }

        function gridTouchEnd(e: React.TouchEvent) {
            self.mouseIsDown = false;
            self.refOnDown = null;
        }

        function togglePause() {
            if (!value.play?.completed) {
                if (value.play?.paused) {
                    value.doAction!(new RestartAction());
                } else {
                    value.doAction!(new PauseAction());
                }
            }
        }

        function handleDelete() {
            let action = getDeleteAction(value.game!);
            if (action) {
                value.doAction!(action);
            }
        }

        return <>
            <svg xmlns="http://www.w3.org/2000/svg"
                 viewBox={`${-gutter} ${-gutter} ${width + 2 * gutter} ${width + 2 * gutter}`}
                 style={{
                     maxWidth: this.props.maxWidth,
                     maxHeight: this.props.maxHeight,
                 }}
                 id="game-board">
                <Background setup={value.setup!}/>
                {
                    value.play!.paused ?
                        <Paused size={width} /> :
                        <>
                            <Highlights play={value.play!}/>
                            <Constraints setup={value.setup!} />
                            <Selection selection={value.selection!} />
                            <ForegroundConstraints setup={value.setup!} />
                            <Gridlines setup={value.setup!} />
                            <Digits setup={value.setup!} play={value.play!} />
                            <Conflicts conflicts={value.conflicts! || []} />
                            <rect x={0} y={0} width={width} height={width}
                                  fill="transparent"
                                  cursor="pointer"
                                  onMouseDown={gridMouseDown}
                                  onTouchStart={gridTouchStart}
                                  onMouseMove={gridMouseMove}
                                  onTouchMove={gridTouchMove}
                                  onMouseUp={gridMouseUp}
                                  onTouchEnd={gridTouchEnd}
                                  onMouseLeave={gridMouseUp}
                                />
                        </>
                }
            </svg>

            <KeyBinding matchKey={/^Arrow/} onKeyPress={e => handleArrowKey(e)} final={true} />
            <KeyBinding matchKey=" " onKeyPress={() => toggleSelection()} final={true} />
            <KeyBinding
                matchKey={/^[0-9]$/}
                shift={false} ctrl={false}
                alt={false} onKeyPress={e => handleDigit(e)} final={true} />
            <KeyBinding matchKey="z" ctrl={true} alt={false} onKeyPress={() => undo()} final={true} />
            <KeyBinding matchKey="y" ctrl={true} alt={false} onKeyPress={() => redo()} final={true} />
            <KeyBinding matchKey="a" ctrl={true} alt={false} onKeyPress={() => selectAll()} final={true} />
            <KeyBinding matchKey="d" ctrl={true} alt={false} onKeyPress={() => deselectAll()} final={true} />
            <KeyBinding matchKey={/^[zxcv]$/i} ctrl={false} alt={false} onKeyPress={e => setEntryMode(e)} final={true} />
            <KeyBinding matchKey="p" ctrl={true} shift={false} alt={false} onKeyPress={() => togglePause()} final={true} />
            <KeyBinding matchKey={/^(Backspace|Delete)$/} shift={false} ctrl={false} alt={false} onKeyPress={() => handleDelete()} final={true} />
        </>;
    }

    render() {
        return (
            <GameContext.Consumer>{this.renderContents}</GameContext.Consumer>
        );
    }
}
