import {CellRef} from "../../common";
import {Constraint} from "../constraints";
import {Cell, Grid} from "../grid";

export class KingsMoveConstraint extends Constraint {
    readonly wrapAround: boolean;

    constructor(wrapAround: boolean) {
        super();
        this.wrapAround = wrapAround;
    }

    override getCellsVisibleTo(grid: Grid, ref: CellRef) {
        let cells: Cell[] = [];
        for (let a = -1; a <= 1; a++) {
            for (let b = -1; b <= 1; b++) {
                if (a * b !== 0) {
                    let newRef = CellRef.get(ref.row + a, ref.column + b);
                    if (this.wrapAround) {
                        newRef = grid.wrap(newRef);
                    }

                    if (grid.checkBounds(newRef)) {
                        let cell = grid.getCell(newRef);
                        cells.push(cell);
                    }
                }
            }
        }

        return cells;
    }

    override get name(): string {
        return "king's move";
    }

    override get rules(): string {
        return "Cells separated by a king's move (in chess) can not contain the same digit.";
    }
}