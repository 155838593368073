import {Game} from "./game";
import {CellRef} from "../common";
import {Cell} from "./grid";

export enum UpdateSection {
    none = 0,
    selection = 1 << 0,
    design = 1 << 1,
    metadata = 1 << 2,
    play = 1 << 3,
    conflicts = 1 << 4,
    controls = 1 << 5,
    all = ~(~0 << 6),
    undoable = all ^ (selection | controls)
}

export enum UndoMode {
    /**
     * The action should not be added to the undo list.
     */
    none,
    /**
     * The action should be added to the list, but can not be undone.
     */
    loading,
    /**
     * The action can be undone at design time only.
     */
    design,
    /**
     * The action can be undone either at design time or during play.
     */
    play
}


export class ActionType {
    /**
     * Indicates which sections of the game will be updated by this action.
     */
    readonly sections: UpdateSection;
    /**
     * Indicates when the action can be undone.
     */
    readonly undoMode: UndoMode;

    constructor(sections: UpdateSection, undoMode: UndoMode) {
        this.sections = sections;
        this.undoMode = undoMode;
    }

    /**
     * Indicates whether any of the given section(s) are updated by this action.
     * @param section The section to query.
     */
    updates(section: UpdateSection) : boolean {
        return (section & this.sections) !== 0;
    }

    /**
     * Indicates whether this action should be added to the undo/redo list.
     */
    get shouldStore(): boolean {
        return this.undoMode !== UndoMode.none;
    }

    /**
     * Indicates whether the action can be undone in the current mode.
     * @param designMode Whether the grid is in design mode.
     */
    canUndo(designMode: boolean) {
        return this.undoMode === UndoMode.play ||
            (this.undoMode === UndoMode.design && designMode);
    }

    /* ====== Predefined action types ====== */

    /**
     * Loading a puzzle from an external source (e.g. an Ajax request or a query
     * string). Will be applied at the outset, but can not be undone.
     */
    static readonly loading = new ActionType(UpdateSection.undoable, UndoMode.loading);
    /**
     * Actions performed while setting the puzzle (e.g. placing given digits,
     * adding or removing constraints, or changing any of the puzzle's metadata
     * such as author or description). Can be undone in setting mode but not
     * in solving mode.
     */
    static readonly setting = new ActionType(UpdateSection.design, UndoMode.design);
    /**
     * Actions performed while solving the puzzle (e.g. placing digits, adding
     * candidates or hints, or highlighting cells). Can be undone in either
     * setting mode or solving mode.
     */
    static readonly solving = new ActionType(UpdateSection.play, UndoMode.play);
    /**
     * Selection/deselection actions. These will not be added to the undo/redo
     * list (unless configured to do so in the options).
     */
    static readonly selecting = new ActionType(UpdateSection.selection, UndoMode.none);
    /**
     * Undo/redo actions and other control events. These will not be added to
     * the undo/redo list under any circumstances, but they will require the
     * whole grid to be redrawn.
     */
    static readonly undoRedo = new ActionType(UpdateSection.all, UndoMode.none);
    /**
     * Control actions that do not affect the grid. They do not get added to the
     * undo/redo list, and they do not update the grid's props. They may,
     * however, change the state of the control panel.
     */
    static readonly control = new ActionType(UpdateSection.controls, UndoMode.none);
    /**
     * Actions for pausing or restarting the timer. These are given a free pass
     * to execute when the game is paused.
     */
    static readonly timer = new ActionType(UpdateSection.all, UndoMode.none);
}


/* ====== Action class ====== */

/**
 * The base class for all actions.
 */

export class Action {
    readonly type: ActionType;

    constructor(type: ActionType) {
        this.type = type;
    }

    apply(game: Game) {
    }

    get allowOnPause() : boolean {
        return !this.type.updates(UpdateSection.play) &&
            !this.type.updates(UpdateSection.selection)
    }
}


/* ====== CellAction class ====== */

/**
 * The base class for all actions that are performed on one or more cells.
 * Will be applied to all selected cells in the grid, or to the cell at the
 * grid's cursor if there is no selection.
 */

export class CellAction extends Action {
    readonly cells: CellRef[];

    forEachCell(game: Game, action: (cell: Cell) => boolean): number {
        let count = 0;
        this.cells.forEach(ref => {
            if (action(game.grid.getCell(ref))) {
                count++;
            };
        });

        return count;
    }

    constructor(game: Game, type: ActionType) {
        super(type);

        let cells = game.grid.getCells(cell => cell.selected).map(cell => cell.ref);
        if (!cells.length) {
            cells = [game.grid.cursor];
        }
        this.cells = cells;
    }
}
