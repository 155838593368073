import _ from "lodash";
import {CellRef} from "../../common";
import {Conflict, Constraint} from "../constraints";
import {Cell, Grid} from "../grid";

export class Arrow extends Constraint {
    readonly rootRefs: CellRef[];
    readonly arrowRefs: CellRef[];

    constructor(rootRefs: CellRef[], arrowRefs: CellRef[]) {
        super();
        this.rootRefs = rootRefs;
        this.arrowRefs = arrowRefs;
    }

    /* ====== horizontal ====== */

    /**
     * Checks if all rows in the root are on consecutive cells in the same row.
     */

    get horizontal() : boolean {
        if (this.rootRefs.length === 0) {
            return false;
        }

        for (let ix = 1; ix < this.rootRefs.length; ix++) {
            if ((this.rootRefs[ix].row !== this.rootRefs[0].row) ||
                (this.rootRefs[ix].column !== this.rootRefs[0].column + ix)) {
                return false;
            }
        }

        return true;
    }

    /**
     * Checks if all rows in the root are on consecutive cells in the same column.
     */

    get vertical() : boolean {
        if (this.rootRefs.length === 0) {
            return false;
        }

        for (let ix = 1; ix < this.rootRefs.length; ix++) {
            if ((this.rootRefs[ix].column !== this.rootRefs[0].column) ||
                (this.rootRefs[ix].row !== this.rootRefs[0].row + ix)) {
                return false;
            }
        }

        return true;
    }

    override getCellsVisibleTo(grid: Grid, ref: CellRef): Cell[] {
        return [];
    }

    override getConflicts(grid: Grid, ref: CellRef): Conflict[] {
        let rootDigits = this.rootRefs.map(r => grid.getCell(r).digit);
        let arrowRefs = _.difference(this.arrowRefs, this.rootRefs);
        let arrowDigits = arrowRefs.map(r => grid.getCell(r).digit);

        if (rootDigits.length * arrowDigits.length === 0) {
            return [];
        }

        if (
            rootDigits.some(d => ! _.isNumber(d)) ||
            arrowDigits.some(d => ! _.isNumber(d))) {
            return [];
        }

        let rootTotal = rootDigits.reduce((prev, curr) => prev! * 10 + curr!);
        let arrowTotal = _.sum(arrowDigits);

        if (rootTotal !== arrowTotal) {
            let conflicts = this.rootRefs.concat(...this.arrowRefs)
                .map(r =>
                    new Conflict(
                        this,
                        r,
                        r,
                        `Cells on the arrow sum to ${arrowTotal} ` +
                        `which is not the total ${rootTotal} given in the cells at the base of the arrow.`
                    )
            );

            return _.flatten(conflicts);
        }
        else {
            return [];
        }
    }

    override get name(): string {
        return "arrow";
    }

    override get rules(): string {
        return "Digits on arrows must sum to the number given by the cells at the base of the arrow.";
    }
}