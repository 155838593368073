import {Action, ActionType} from "../actions";
import {Game} from "../game";
import {CellRef} from "../../common";


/* ====== MoveCursorAction ====== */

export class MoveCursorAction extends Action {
    private dRow: number;
    private dColumn: number;
    private extend: boolean;
    private preserve: boolean;

    constructor(dRow: number, dColumn: number, extend: boolean, preserve: boolean) {
        super(ActionType.selecting);
        this.dRow = dRow;
        this.dColumn = dColumn;
        this.extend = extend;
        this.preserve = preserve;
    }

    override apply(game: Game) {
        super.apply(game);
        let ref = game.grid.cursor;
        let newRef = game.grid.getOffset(game.grid.cursor, this.dRow, this.dColumn);
        game.grid.cursor = newRef;
        if (!this.preserve) {
            game.grid.forEachCell(cell => cell.selected = false);
        }

        if (this.extend) {
            game.grid.getCell(ref).selected = true;
            game.grid.getCell(newRef).selected = true;
        }
    }
}

/* ====== SetCursorAction ====== */

export class SetCursorAction extends Action {
    private ref: CellRef;
    private toggle: boolean;
    private extend: boolean;

    constructor(ref: CellRef, toggle: boolean, extend: boolean) {
        super(ActionType.selecting);
        this.ref = ref;
        this.toggle = toggle;
        this.extend = extend;
    }

    override apply(game: Game) {
        super.apply(game);
        if (this.extend) {
            let cell = game.grid.getCell(game.grid.cursor);
            cell.selected = true;
        }

        game.grid.cursor = this.ref;
        if (this.toggle) {
            let cell = game.grid.getCell(this.ref);
            cell.selected = !cell.selected;
        }
        else if (this.extend) {
            let cell = game.grid.getCell(this.ref);
            cell.selected = true;
        }
        else {
            game.grid.forEachCell(cell => cell.selected = false);
        }
    }
}


/* ====== ToggleSelectionAction ====== */

export class ToggleSelectionAction extends Action {
    constructor() {
        super(ActionType.selecting);
    }

    override apply(game: Game) {
        let cell = game.grid.getCell(game.grid.cursor);
        cell.selected = !cell.selected;
    }
}


/* ====== SelectAllAction ====== */

export class SelectOrDeselectAllAction extends Action {
    private readonly selected: boolean;

    constructor(selected: boolean) {
        super(ActionType.selecting);
        this.selected = selected;
    }

    override apply(game: Game) {
        game.grid.forEachCell(cell => cell.selected = this.selected);
    }
}
