import React from "react";
import {Settings, loadSettings, saveSettings} from "../models/settings";
import _ from "lodash";

interface SettingsContextData {
    settings: Settings;
    update: (settings: Settings) => any;
}

interface SettingsProviderState {
    settings: Settings
}

let defaultSettings = loadSettings();

export class SettingsProvider extends React.Component<any, SettingsProviderState> {

    constructor(props: any) {
        super(props);
        this.state = {
            settings: defaultSettings
        };

        this.onUpdate = this.onUpdate.bind(this);
    }

    onUpdate(changes: Settings) {
        let newSettings = {...this.state.settings};
        _.assign(newSettings, changes);

        this.setState({
            settings: newSettings
        }, () => {
            saveSettings(newSettings);
        });
    }

    render() {
        let contextData: SettingsContextData = {
            settings: this.state.settings,
            update: this.onUpdate
        }

        return (
            <SettingsContext.Provider value={contextData}>
                {this.props.children}
            </SettingsContext.Provider>
        );
    }
}

export const SettingsContext = React.createContext<SettingsContextData>({
    settings: defaultSettings,
    update: settings => {}
});
