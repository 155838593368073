import * as constraints from "../../../models/game/constraints/diagonal";
import {Grid} from "../../../models/game/grid";

export class DiagonalProps {
    size: number;
    forward: boolean;

    constructor(grid: Grid, diagonal: constraints.DiagonalConstraint) {
        this.size = grid.size;
        this.forward = diagonal.forward;
    }
}

export function Diagonal(props: DiagonalProps) {
    if (props.forward) {
        return <line
            x1={0} y1={props.size * 100}
            x2={props.size * 100} y2={0}
            className="diagonal-constraint" />
    }
    else {
        return <line
            x1={0} y1={0}
            x2={props.size * 100} y2={props.size * 100}
            className="diagonal-constraint" />
    }
}