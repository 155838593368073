import {Action, ActionType, CellAction, UndoMode, UpdateSection} from "../actions";
import {EntryMode, Game} from "../game";

/* ====== HandleDigitAction ====== */

/**
 * Handles placed or given digits.
 */

export class HandleDigitAction extends CellAction {
    digit: number;
    designMode: boolean;

    private static readonly solvingActionType = new ActionType(
        UpdateSection.play | UpdateSection.conflicts,
        UndoMode.play
    );

    constructor(game: Game, digit: number) {
        super(game, game.designMode ? ActionType.setting : HandleDigitAction.solvingActionType);
        this.designMode = game.designMode;
        this.digit = Math.floor(digit);
    }

    override apply(game: Game) {
        let count = this.forEachCell(game, cell => {
            // Don't overwrite a given digit
            if (cell.isGiven && !this.designMode) {
                return false;
            }

            // Don't change anything if the digit is the same
            if (cell.digit === this.digit) {
                return false;
            }

            // Otherwise set the digit.
            cell.digit = this.digit;
            cell.isGiven = this.designMode;
            return true;
        });

        // If nothing was changed, let's clear out what's already there.

        if (count === 0) {
            this.forEachCell(game, cell => {
                if (cell.isGiven && !this.designMode) {
                    return false;
                }

                if (cell.digit !== this.digit) {
                    return false;
                }

                delete cell.digit;
                cell.isGiven = false;
                return true;
            });
        }
    }
}


/* ====== HandleCandidateAction ====== */

export class HandleCandidateAction extends CellAction {
    digit: number;
    entryMode: EntryMode;

    constructor(game: Game, digit: number) {
        super(game, ActionType.solving);
        this.digit = Math.floor(digit);
        this.entryMode = game.entryMode;
    }

    addOrRemove(game: Game, remove: boolean): number {
        return this.forEachCell(game, cell => {
            let collection: Set<number>;
            switch (this.entryMode) {
                case EntryMode.Candidates:
                    if (typeof(cell.digit) === 'number') {
                        return false;
                    }
                    collection = cell.candidates;
                    break;
                case EntryMode.Hints:
                    if (typeof(cell.digit) === 'number') {
                        return false;
                    }
                    collection = cell.hints;
                    break;
                case EntryMode.Highlights:
                    collection = cell.highlights;
                    break;
                default:
                    return false;
            }

            if (collection.has(this.digit) && remove) {
                collection.delete(this.digit);
                return true;
            }
            else if (!collection.has(this.digit) && !remove) {
                collection.add(this.digit);
                return true;
            }
            else {
                return false;
            }
        });
    }

    override apply(game: Game) {
        if (this.addOrRemove(game, false) === 0) {
            this.addOrRemove(game, true);
        }
    }
}


export function getDigitAction(game: Game, digit: number) : CellAction {
    switch (game.entryMode) {
        case EntryMode.Place:
            return new HandleDigitAction(game, digit);
        default:
            return new HandleCandidateAction(game, digit);
    }
}


/* ====== Delete actions ====== */


export class DeleteAction extends CellAction {

    private static readonly solvingActionType = new ActionType(
        UpdateSection.play | UpdateSection.conflicts,
        UndoMode.play
    );

    private mode: EntryMode;
    private designMode: boolean;

    constructor(game: Game, mode: EntryMode) {
        super(game, DeleteAction.solvingActionType);
        this.mode = mode;
        this.designMode = game.designMode;
    }

    execute(game: Game, dryRun: boolean) {
        switch (this.mode) {
            case EntryMode.Place:
                return this.forEachCell(game, c => {
                    if (this.designMode || !c.isGiven) {
                        let d = typeof (c.digit) === "number";
                        if (!dryRun) delete c.digit
                        return d;
                    } else {
                        return false;
                    }
                });
            case EntryMode.Candidates:
                return this.forEachCell(game, c => {
                    let d = c.candidates.size > 0;
                    if (!dryRun) c.candidates.clear();
                    return d;
                });
            case EntryMode.Hints:
                return this.forEachCell(game, c => {
                    let d = c.hints.size > 0;
                    if (!dryRun) c.hints.clear();
                    return d;
                });
            case EntryMode.Highlights:
                return this.forEachCell(game, c => {
                    let d = c.highlights.size > 0;
                    if (!dryRun) c.highlights.clear();
                    return d;
                });
            default:
                return 0;
        }
    }

    override apply(game: Game) {
        this.execute(game, false);
    }
}


export function getDeleteAction(game: Game) : DeleteAction | null {
    var actions: DeleteAction[] = [
        new DeleteAction(game, game.entryMode),
        new DeleteAction(game, EntryMode.Place),
        new DeleteAction(game, EntryMode.Highlights),
        new DeleteAction(game, EntryMode.Candidates),
        new DeleteAction(game, EntryMode.Hints)
    ];

    for (let ac of actions) {
        if (ac.execute(game, true) > 0) {
            return ac;
        }
    }

    return null;
}


/* ====== Pause and restart actions ====== */

export class PauseAction extends Action {

    constructor() {
        super(ActionType.timer)
    }

    override apply(game: Game) {
        game.timer.stop();
    }
}

export class RestartAction extends Action {
    constructor() {
        super(ActionType.timer)
    }

    override apply(game: Game) {
        game.timer.start();
    }

    override get allowOnPause(): boolean {
        return true;
    }
}