import _ from "lodash";
import {CellRef} from "../../common";
import {Conflict, Constraint} from "../constraints";
import {Cell, Grid} from "../grid";

export enum CageArithmetic {
    /**
     * Additive killer cage: digits must sum to the numbers in the top left hand
     * corner of the cage.
     */
    additive = 0,
    /**
     * Multiplicative killer cage: digits must multiply to the numbers in the
     * top left hand corner of the cage.
     */
    multiplicative = 1
}

export class KillerCage extends Constraint {

    readonly refs: CellRef[];
    readonly total?: number;
    readonly strict: boolean;
    readonly arithmetic: CageArithmetic;

    constructor(refs: CellRef[], strict: boolean, arithmetic: CageArithmetic, total?: number) {
        super();
        this.refs = refs;
        this.total = total;
        this.strict = strict || !_.isNumber(total);
        this.arithmetic = arithmetic;
    }

    override getCellsVisibleTo(grid: Grid, ref: CellRef): Cell[] {
        if (this.strict) {
            return this.refs.map(r => grid.getCell(r));
        }
        else {
            return [];
        }
    }

    override getConflicts(grid: Grid, ref: CellRef): Conflict[] {
        let conflicts = super.getConflicts(grid, ref);
        if (!_.isNumber(this.total)) {
            return conflicts;
        }

        let digits = this.refs.map(r => grid.getCell(r).digit);

        if (_.findIndex(digits, digit => !_.isNumber(digit)) > -1) {
            console.log("Not all cells have digits placed.");
            return conflicts;
        }

        let total: number;
        switch (this.arithmetic) {
            case CageArithmetic.additive:
                total = _.sum(digits);
                break;
            case CageArithmetic.multiplicative:
                total = digits.reduce((prev, curr) => prev! * curr!, 1) as number;
                break;
        }

        if (total !== this.total) {
            let moreConflicts = this.refs.map(r1 =>
                new Conflict(this, r1, r1,
                    "The numbers in the cage do not " +
                    (this.arithmetic === CageArithmetic.additive ? "add up" : "multiply") +
                    `to the cage total of ${this.total}.`
                )
            );

            conflicts = _.concat(conflicts, _.flatten(moreConflicts));
        }

        return conflicts;
    }

    override get name(): string {
        return "killer cage";
    }

    override get rules(): string {
        return "Numbers in cages must " +
            (this.arithmetic === CageArithmetic.additive ? "add up" : "multiply") +
            "to the number in the top left hand corner of the cage. " +
            this.strict
                ? "Numbers can not repeat within cages."
                : "Numbers within cages can repeat if allowed to do so by other rules.";
    }
}