import "./gridlines.css";

import _ from "lodash";
import {CellSetup, SetupProps} from "./props";
import {Cage} from "./rendering/cage";

export function Gridlines(props: SetupProps) {
    let size = props.setup.size;
    let regions = _.groupBy<CellSetup>(props.setup.getCells(c => true), c => c.region);

    _.keys()

    return (
        <g className="gridlines">
            <g className="gridlines-grid">
            {
                _.range(0, size + 1).map(row =>
                    <line x1={0} y1={row * 100} x2={size * 100} y2={row * 100} key={`r${row}`} />
                )
            }
            {
                _.range(0, size + 1).map(col =>
                    <line x1={col * 100} y1={0} x2={col * 100} y2={size * 100} key={`c${col}`} />
                )
            }
            </g>
            <g className="gridlines-regions">
                {
                    _.keys(regions).map((key, index) =>
                        <Cage
                            cells={regions[key].map(r => r.ref)}
                            className="region" offset={0} key={key} />)
                }
            </g>
        </g>
    );
}