import {CellRef} from "../../../models/common";

export interface LineProps extends React.SVGProps<SVGPathElement> {
    readonly cells: CellRef[];
    readonly roundness?: number;
}

export function Line(props: LineProps) {

    if (props.cells.length === 0) {
        return <></>;
    }

    let roundness = typeof(props.roundness) === "number"
        ? props.roundness
        : 25;

    let row = props.cells[0].row;
    let column = props.cells[0].column;

    let pathStr = `M ${column * 100 - 50} ${row * 100 - 50} `;

    for (let i = 1; i < props.cells.length - 1; i++) {
        let row = props.cells[i].row;
        let column = props.cells[i].column;
        let inRow = Math.sign(row - props.cells[i - 1].row);
        let inColumn = Math.sign(column - props.cells[i - 1].column);
        let outRow = Math.sign(props.cells[i + 1].row - row);
        let outColumn = Math.sign(props.cells[i + 1].column - column);

        let x = column * 100 - 50;
        let y = row * 100 - 50;
        let x1 = x - roundness * inColumn;
        let y1 = y - roundness * inRow;
        let x2 = x + roundness * outColumn;
        let y2 = y + roundness * outRow;

        pathStr +=  `L ${x1} ${y1} Q ${x} ${y} ${x2} ${y2} `;
    }

    row = props.cells[props.cells.length - 1].row;
    column = props.cells[props.cells.length - 1].column;

    pathStr += `L ${column * 100 - 50} ${row * 100 - 50}`;

    return <path d={pathStr} {...props} />;
}