import React from "react";
import {GameContext} from "./gameContext";
import {Game} from "../models/game/game";
import {PauseAction, RestartAction} from "../models/game/actions/solving";
import {Button, ButtonProps} from "react-bootstrap";
import {PauseCircleFill, PlayCircleFill} from "react-bootstrap-icons";

export interface TimerProps {
}

export interface TimerState {
    elapsed: string;
    running: boolean;
}


export class ElapsedTime extends React.Component<TimerProps, TimerState> {
    static contextType = GameContext;
    private handle?: number;

    constructor(props: TimerProps) {
        super(props);
        this.refresh = this.refresh.bind(this);
        this.state = {
            elapsed: "",
            running: false
        };
    }

    refresh() {
        let game: Game = this.context.game;
        this.setState({
            elapsed: game.timer.toString(),
            running: game.timer.running
        });
    }

    componentDidMount() {
        this.handle = window.setInterval(this.refresh, 200);
    }

    componentWillUnmount() {
        window.clearInterval(this.handle);
    }

    render() {
        return <GameContext.Consumer>
            {value => <>{this.state.elapsed}</>}
        </GameContext.Consumer>
    }
}


export function TimerButton(props: ButtonProps) {

    return <GameContext.Consumer>
        {value => {

            function pause() {
                value.doAction!(new PauseAction());
            }

            function restart() {
                value.doAction!(new RestartAction());
            }

            return value.game!.timer.running ?
                <Button onClick={pause} {...props}>
                    <PauseCircleFill />
                </Button> :
                <Button onClick={restart} {...props}>
                    <PlayCircleFill />
                </Button>;
        }}
    </GameContext.Consumer>;
}