import {CellRef} from "../../common";
import {Conflict, Constraint} from "../constraints";
import {Cell, Grid} from "../grid";

export class NonConsecutiveConstraint extends Constraint {
    wrapAround: boolean;

    constructor(wrapAround: boolean) {
        super();
        this.wrapAround = wrapAround;
    }

    override getCellsVisibleTo(grid: Grid, ref: CellRef): Cell[] {
        let cells = [];
        for (let a = -1; a <= 1; a += 2) {
            for (let b = 0; b <= 1; b++) {
                let newRow = ref.row + a * b;
                let newColumn = ref.column + a * (1 - b);
                let newRef = CellRef.get(newRow, newColumn);
                if (this.wrapAround) {
                    newRef = grid.wrap(newRef);
                }

                if (grid.checkBounds(newRef)) {
                    cells.push(grid.getCell(newRef));
                }
            }
        }

        return cells;
    }

    override checkConflict(grid: Grid, ref1: CellRef, ref2: CellRef, value1?: number, value2?: number): Conflict | null {
        if (value1 && value2) {
            if (Math.abs(value1 - value2) <= 1) {
                return new Conflict(this, ref1, ref2,
                    `Orthogonally adjacent cells ${ref1}=${value1} and ${ref2}=${value2} must not be consecutive.`
                    );
            }
        }

        return null;
    }

    override get name(): string {
        return "nonconsecutive";
    }

    override get rules(): string {
        return "Orthogonally adjacent cells must not contain consecutive digits.";
    }
}