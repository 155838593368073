import {CellRef, CellBase, GridBase} from "../../models/common";
import {Arrow} from "../../models/game/constraints/arrow";
import {DiagonalConstraint} from "../../models/game/constraints/diagonal";
import {KillerCage} from "../../models/game/constraints/killerCage";
import {Cell, Grid} from "../../models/game/grid";
import {Conflict} from "../../models/game/constraints";
import {Game} from "../../models/game/game";
import {ArrowProps} from "./constraints/arrow";
import {DiagonalProps} from "./constraints/diagonal";
import {KillerCageProps} from "./constraints/killerCage";
import {ThermometerProps} from "./constraints/thermometer";
import {Thermometer} from "../../models/game/constraints/thermometer";


/* ====== Game setup ====== */

export class CellSetup extends CellBase {
    readonly region: number;
    readonly givenDigit?: number;

    constructor(cell: Cell) {
        super(cell.ref);
        this.region = cell.region;
        if (cell.digit && cell.isGiven) {
            this.givenDigit = cell.digit;
        }
    }
}

export class GridSetup extends GridBase<CellSetup> {
    readonly thermos: ThermometerProps[];
    readonly diagonals: DiagonalProps[];
    readonly killerCages: KillerCageProps[];
    readonly arrows: ArrowProps[];

    constructor(grid: Grid) {
        super(grid.size, ref => new CellSetup(grid.getCell(ref)));
        this.thermos = grid.constraints.filter(c => c.name === "thermometer")
            .map(c => new ThermometerProps(c as Thermometer));
        this.diagonals = grid.constraints.filter(c => c.name.indexOf("diagonal") >= 0)
            .map(c => new DiagonalProps(grid, c as DiagonalConstraint));
        this.killerCages = grid.constraints.filter(c => c.name === "killer cage")
            .map(c => new KillerCageProps(c as KillerCage));
        this.arrows = grid.constraints.filter(c => c.name === "arrow")
            .map(c => new ArrowProps(c as Arrow));
    }
}


/* ====== Cell selection ====== */

export class CellSelection extends CellBase {
    readonly selected: boolean;

    constructor(cell: Cell) {
        super(cell.ref);
        this.selected = cell.selected;
    }
}

export class GridSelection extends GridBase<CellSelection> {
    readonly cursor: CellRef;

    constructor(grid: Grid) {
        super(grid.size, ref => new CellSelection(grid.getCell(ref)));
        this.cursor = grid.cursor;
    }
}


/* ====== Game play ====== */

export class CellPlay extends CellBase {
    readonly digit?: number;
    readonly candidates: number[];
    readonly hints: number[];
    readonly highlights: number[];

    constructor(cell: Cell) {
        super(cell.ref);
        this.candidates = [...cell.candidates].sort((a, b) => a - b);
        this.hints = [...cell.hints].sort((a, b) => a - b);
        this.highlights = [...cell.highlights].sort((a, b) => a - b);
        if (cell.digit && !cell.isGiven) {
            this.digit = cell.digit;
        }
    }
}

export class GridPlay extends GridBase<CellPlay> {
    constructor(grid: Grid, game: Game) {
        super(grid.size, ref => new CellPlay(grid.getCell(ref)));
        this.completed = game.completed;
        this.completionTime = game.timer.toString();
        this.paused = !game.timer.running && !game.completed;
    }

    completed: boolean;
    completionTime: string;
    paused: boolean;
}


/* ====== Propses ====== */

export interface SetupProps {
    setup: GridSetup;
}

export interface SelectionProps {
    selection: GridSelection;
}

export interface PlayProps {
    play: GridPlay;
}

export interface ConflictProps {
    conflicts: Conflict[];
}

export interface GridProps extends SetupProps, SelectionProps, PlayProps, ConflictProps {
    maxWidth?: number;
    maxHeight?: number;
}
