import React from "react";
import {EntryMode} from "../../models/game/game";
import {Button, ButtonGroup} from "react-bootstrap";
import {GameContext} from "../gameContext";
import {SetEntryModeAction} from "../../models/game/actions/control";

export interface EntryModeSelectorProps {
    mode: EntryMode;
}

export function EntryModeSelector(props: EntryModeSelectorProps) {
    return (
        <GameContext.Consumer>
            {value => {
                function onModeChange(mode: EntryMode) {
                    if (value.game && value.doAction) {
                        var action = new SetEntryModeAction(mode);
                        value.doAction(action);
                    }
                }

                return (
                    <div className="entry-mode-selector">
                        <ButtonGroup>
                            <Button
                                active={props.mode === EntryMode.Place}
                                onClick={() => onModeChange(EntryMode.Place)}>
                                Place
                            </Button>
                            <Button
                                active={props.mode === EntryMode.Hints}
                                onClick={() => onModeChange(EntryMode.Hints)}>
                                Edges
                            </Button>
                            <Button
                                active={props.mode === EntryMode.Candidates}
                                onClick={() => onModeChange(EntryMode.Candidates)}>
                                Centre
                            </Button>
                            <Button
                                active={props.mode === EntryMode.Highlights}
                                onClick={() => onModeChange(EntryMode.Highlights)}>
                                Highlight
                            </Button>
                        </ButtonGroup>
                    </div>
                );
            }}
        </GameContext.Consumer>
    );
}