import "./text.css";
import {CellRef} from "../../../models/common";
import {CellGroup} from "./cellGroup";

export interface TextProps {
    cellRef: CellRef;
    dx: number;
    dy: number;
    text: string;
    className: string;
}

export function Text(props: TextProps) {
    return (
        <CellGroup cellRef={props.cellRef} className={`grid-text ${props.className}`}>
            <text dx={props.dx} dy={props.dy} className="text-outline">{props.text}</text>
            <text dx={props.dx} dy={props.dy} className="text-fill">{props.text}</text>
        </CellGroup>
    )
}