export interface PausedProps {
    size: number;
}

export function Paused(props: PausedProps) {
    return (
        <text
            x={props.size/2} y={props.size/2}
            fontSize={200}
            alignmentBaseline="central"
            fontWeight="bold"
            textAnchor="middle">Paused</text>
    );
}