import "./selection.css";

import {SelectionProps} from "./props";
import {CellGroup} from "./rendering/cellGroup";
import {Cage} from "./rendering/cage";

export function Selection(props: SelectionProps) {
    let selectionCellRefs = props.selection.getCells(cell => cell.selected)
        .map(cell => cell.ref);

    return (
        <g className="grid-selection">
            <Cage
                cells={selectionCellRefs}
                offset={6}
                className="grid-selection-outline" />
            {
                <CellGroup cellRef={props.selection.cursor} className="grid-cursor">
                    <circle r={40} cx={50} cy={50}/>
                </CellGroup>
            }
        </g>
    )
}