import "./game.css";

import React from "react";
import {Alert, Col, Container, Modal, Row} from "react-bootstrap";
import {withResizeDetector} from "react-resize-detector";

import {Grid} from "./grid/grid";
import {GameContext} from "./gameContext";
import {Metadata} from "./metadata";
import {ControlPanel} from "./controlPanel/controlPanel";
import {ElapsedTime, TimerButton} from "./timer";

export class GameProps {
    width?: number;
    height?: number;
    targetRef?: any;
}

class GameContainerInternal extends React.Component<GameProps> {

    render() {
        return (
            <GameContext.Consumer>
                {value => {
                    return (
                        <Container id="game-container" fluid>
                            <Row className="game-row">
                                <Col xs={3} className="grid-metadata">
                                    <Metadata {...value.game!.metadata} />
                                    <br />
                                    <Alert variant="primary" className="clearfix align-items-center">
                                        <strong style={{fontSize: "130%"}}>
                                            <ElapsedTime />
                                            &nbsp;
                                            <TimerButton variant="primary" className="float-right" size="sm" />
                                        </strong>
                                    </Alert>
                                </Col>
                                <Col xs={6} className="grid-column" ref={this.props.targetRef}>
                                    <Grid
                                        setup={value.setup!}
                                        selection={value.selection!}
                                        play={value.play!}
                                        conflicts={value.conflicts!}
                                        maxWidth={this.props.width}
                                        maxHeight={this.props.height} />
                                    <CompletedModal
                                        completed={value.play!.completed}
                                        completionTime={value.play!.completionTime}
                                        />
                                </Col>
                                <Col xs={3} className="grid-controls">
                                    <ControlPanel controlState={value.controls!} />
                                </Col>
                            </Row>
                        </Container>
                    )
                }}
            </GameContext.Consumer>
        )
    }
}

export const GameContainer = withResizeDetector(GameContainerInternal);

interface CompletedModalProps {
    completed: boolean;
    completionTime: string;
}

function CompletedModal(props: CompletedModalProps) {
    return (
        <Modal centered show={props.completed} animation={false} onHide={() => {}}>
            <Modal.Header>
                <Modal.Title>All done!</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Puzzle completed in {props.completionTime}.
            </Modal.Body>
        </Modal>
    );
}