import React from "react";

/* ====== KeyBinding ====== */

export interface KeyBindingProps {
    readonly matchKey?: RegExp | string;
    readonly matchCode?: RegExp | string;
    readonly shift?: boolean;
    readonly ctrl?: boolean;
    readonly alt?: boolean;
    readonly final?: boolean;

    onKeyPress(e: KeyboardEvent) : any;
}

export class KeyBinding extends React.Component<KeyBindingProps> {

    constructor(props: KeyBindingProps) {
        super(props);
        this.handleKeyDown = this.handleKeyDown.bind(this);
    }

    private check(str: string, matcher?: string | RegExp) {
        if (!matcher) {
            return false;
        }
        if (typeof(matcher) === 'string') {
            return matcher.toLowerCase() === str.toLowerCase();
        }
        else if (typeof(matcher) === 'object') {
            return !!str.match(matcher);
        }

        return false;
    }

    private handleKeyDown(e: KeyboardEvent) {
        let isMatch = this.check(e.key, this.props.matchKey)
            || this.check(e.code, this.props.matchCode);

        isMatch = isMatch &&
            (typeof (this.props.shift) === "undefined" || this.props.shift === e.shiftKey) &&
            (typeof (this.props.ctrl) === "undefined" || this.props.ctrl === e.ctrlKey) &&
            (typeof (this.props.alt) === "undefined" || this.props.alt === e.altKey);

        if (isMatch) {
            this.props.onKeyPress(e);

            if (this.props.final) {
                e.preventDefault();
            }
        }
    }

    componentDidMount() {
        document.body.addEventListener("keydown", this.handleKeyDown);
    }

    componentWillUnmount() {
        document.body.removeEventListener("keydown", this.handleKeyDown);
    }

    render() {
        return false;
    }
}
